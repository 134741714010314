import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
// import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
// import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { MatCardModule } from '@angular/material/card';
import { TodaysOrderComponent } from './Todays-order.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { AppComponent } from '../../app.component';
import { StepperModule } from '../stepper/stepper.module';
import { PipesModule } from '../pipe/pipe.module';
const routes: Routes = [
    {
        path: 'TodaysOrder',
        component: TodaysOrderComponent
    }
];

@NgModule({
    declarations: [
        TodaysOrderComponent,
        OrderSubItemComponent,
        productInfoComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        // MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        // MatSelectModule,
        MatTableModule,
        MatTabsModule,
        // MatCardModule,
        NgxChartsModule,
        MatExpansionModule,
        SummaryCartModule,
        FlexLayoutModule,
        MatDialogModule,
        StepperModule,
        PipesModule

    ], entryComponents: [
        productInfoComponent,
        OrderSubItemComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class TodaysOrderModule {
}

