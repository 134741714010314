import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GetDiscountedItemsService } from './getDiscountedItems.service';
import { CalculateRetailInitialOrderService } from './calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from './CalculateRetailSmartOrder.service';
import { StepperService } from './stepper.service';
import { MatDialog } from '@angular/material/dialog';
import {EnrollSessionService } from './enrollSession.service'
import { BehaviorSubject } from 'rxjs';
// import { NavigationComponent } from 'app/main/Orders/Navigation-Popup/Navigation-Popup.component';
// import { SmartShipDateModalComponent } from 'app/main/Subscriptions/SmartShipDateModal/SmartShipDateModal.component';
// import { OpportunityModalComponent } from 'app/main/Subscriptions/Opportunity/OpportunityModal.component';

@Injectable({
  providedIn: 'root'
})
export class SharedMethodService implements OnInit {
  fuseConfig: any;
  CustomerID: any;
  AutoOrderID: any;
  ifReload: any = false;
  finalShippingMethod: any;
  IsCallCalculateOrder: any;
  templateUrl: any;
  navigationInfo: any = {};
  subOrderItem: boolean = false;
  isOpenDialog: boolean = false;
  headerSearchValue: '';
  @Output() userLoggedInEvent = new EventEmitter<string>();
  checkSubTotal: BehaviorSubject<any>;
  constructor(
    private _toastr: ToastrService,
    private _getDiscountedItems: GetDiscountedItemsService,
    private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
    private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
    private _stepperService: StepperService,
    private _router: Router,
    private _restService: RestService,
    private _matDialog: MatDialog,
    public _enrollSession: EnrollSessionService,
    public _activatedRouter: ActivatedRoute) {
    this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) || JSON.parse(localStorage.getItem('EnrollSession')) || {};
    this.init();
  }
  init() {
    this.checkSubTotal = new BehaviorSubject(null);
  }
  // *******Next Btn Click******************/
  // Nextbtn(state: string): any {
  //   if (state === 'checkout') {
  //     if (this._enrollSession.data.IsUpGrade) {
  //       this._router.navigate(['/Shipping'], { queryParams: { customerId: this._enrollSession.data.IsUpGrade } });
  //     } else if (this._enrollSession.data.ReferredBy) {
  //       this._router.navigate(['/Shipping'], { queryParams: { giftcard: this._enrollSession.data.ReferredBy } });
  //     } else {
  //       this._router.navigate(['/Shipping']);
  //     }

  //   }
  //   else if (state === 'AddOnProducts') {
  //     if (this._enrollSession.data.IsUpGrade) {
  //       this._router.navigate(['/Shipping'], { queryParams: { customerId: this._enrollSession.data.IsUpGrade } });
  //     } else if (this._enrollSession.data.ReferredBy) {
  //       this._router.navigate(['/Shipping'], { queryParams: { giftcard: this._enrollSession.data.ReferredBy } });
  //     } else {
  //       this._router.navigate(['/AddOnProducts']);
  //     }

  //   } else {
  //     if (this._enrollSession.data.IsUpGrade) {
  //       this._router.navigate(['/Subscriptions'], { queryParams: { customerId: this._enrollSession.data.IsUpGrade } });
  //     } else if (this._enrollSession.data.ReferredBy) {
  //       this._router.navigate(['/Subscriptions'], { queryParams: { giftcard: this._enrollSession.data.ReferredBy } });
  //     } else {
  //       this._router.navigate(['/Subscriptions']);
  //     }
  //   }

  // }
  ngOnInit(): void {
    
  }
  // *************start Add To Cart ContinueRetailOrder***********************************************************************/
   
    ContinueRetailOrder(product: any, quantity: any, type: any): any {
      
      // var isRestricted = false;
      // Store product in EnrollSession
      if (parseInt(quantity, 10) <= 0) {
          return false;
      }
      this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || [];
      if (type === 1) {
          this._enrollSession.data.RetailOrderSummary.Enrollpack = this._enrollSession.data.RetailOrderSummary.Enrollpack || [];
          const enrollOrder = _.find(this._enrollSession.data.RetailOrderSummary.Enrollpack,
              (item: any) => {
                  return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
              });
          if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.Enrollpack)) {
              if (this.subOrderItem) {
                  if (!enrollOrder) {
                      this._enrollSession.data.RetailOrderSummary.Enrollpack.push({
                          ItemCode: product?.ItemCode,
                          Description: product?.Description,
                          Quantity: quantity ? quantity : 1,
                          Price: product.Price,
                          RetailPrice: product?.Other1Price,
                          ShippingPrice: product?.ShippingPrice,
                          TinyImage: product?.TinyPicture,
                          SmallImage: product?.SmallPicture,
                          BV: product?.BusinessVolume,
                          CV: product?.CommissionableVolume,
                          TaxablePrice: product?.TaxablePrice,
                          LongDetail: product?.LongDetail,
                          LongDetail2: product?.LongDetail2,
                          LongDetail3: product?.LongDetail3,
                          LongDetail4: product?.LongDetail4,
                          IsUSOnly: product?.IsUSOnly ? product?.IsUSOnly : false,
                          ParentId: product?.ParentId,
                          IsKitItem: product?.IsDynamicKitMaster
                      });
                  } else {
                      enrollOrder.Quantity = enrollOrder.Quantity+1 || 1;
                  }
              } else {
                  if (!enrollOrder) {
                  // this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
                  this._enrollSession.data.RetailOrderSummary.Enrollpack.push({
                      ItemCode: product?.ItemCode,
                      Description: product?.Description,
                      Quantity: quantity ? quantity : 1,
                      Price: product?.Price,
                      RetailPrice: product?.Other1Price,
                      ShippingPrice: product?.ShippingPrice,
                      TinyImage: product?.TinyPicture,
                      SmallImage: product?.SmallPicture,
                      BV: product?.BusinessVolume,
                      CV: product?.CommissionableVolume,
                      TaxablePrice: product?.TaxablePrice,
                      LongDetail: product?.LongDetail,
                      LongDetail2: product?.LongDetail2,
                      LongDetail3: product?.LongDetail3,
                      LongDetail4: product?.LongDetail4,
                      IsUSOnly: product?.IsUSOnly ? product?.IsUSOnly : false,
                      ParentId: product?.ParentId,
                      IsKitItem: product?.IsDynamicKitMaster
                  });
              } else {
                  enrollOrder.Quantity = enrollOrder.Quantity+1 || 1;
              }
              }
              // *******find shipping method************/

              if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                  this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
              } else {
                  this.finalShippingMethod = '';
              }
              let enrollOrderItems = _.groupBy(this._enrollSession.data.RetailOrderSummary.Enrollpack, 'ParentId')
                let customEnrollItemArray = [];
                for (let [key, value] of Object.entries(enrollOrderItems)) {
                  _.each(value, (item) => {
                    customEnrollItemArray.push(item);
                });
                }
                this._enrollSession.data.RetailOrderSummary.Enrollpack = customEnrollItemArray;

              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
                  // setTimeout(() => {
                  //     if (this._enrollSession.data.RetailOrderSummary.InitialOrderDetails && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal) {
                  //         console.log("second");
                          
                  //         this.checkSubTotal.next(this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal);
                  //     }
                  //     console.log(this._router.url)
                  // }, 2000)
                  // if(this._router.url=='/TodaysOrder')
                  // {
                  //  if(!enrollOrder)
                  //  {
                  // this._router.navigate(['/Enhance']);
                  //  }
                  // }
                  this._toastr.success('Product Added to Cart.', 'Success');
              }
          }
          else {
              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
              }
          }
      }
      else if (type === 2) {
          this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
          const initialOrder = _.find(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
              return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
          });
          if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.InitialOrders)) {
              if (!initialOrder) {// set order
                  this._enrollSession.data.RetailOrderSummary.InitialOrders.push({
                      ItemCode: product?.ItemCode,
                      Description: product?.Description,
                      Quantity: quantity ? quantity : 1,
                      Price: product?.Price,
                      RetailPrice: product?.Other1Price,
                      ShippingPrice: product?.ShippingPrice,
                      TinyImage: product?.TinyPicture,
                      SmallImage: product?.SmallPicture,
                      BV: product?.BusinessVolume,
                      CV: product?.CommissionableVolume,
                      TaxablePrice: product?.TaxablePrice,
                      LongDetail: product?.LongDetail,
                      LongDetail2: product?.LongDetail2,
                      LongDetail3: product?.LongDetail3,
                      LongDetail4: product?.LongDetail4,
                      ParentId: product?.ParentId,
                      IsUSOnly: product?.IsUSOnly ? product?.IsUSOnly : false
                  });
              }
              else {
                initialOrder.Quantity = initialOrder.Quantity+1
              }
              // *******shipping method**********/
              if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                  this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
              } else {
                  this.finalShippingMethod = '';
              }

              // **********calculate order**********************/

              if (this.IsCallCalculateOrder) {
                  this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
              }
              else {
                  this._enrollSession.data.InitialOrdersSubTotal = 0;
                  this._enrollSession.data.InitialOrdersQVTotal = 0;
                  _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                      this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
                      this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
                  });
              }
              this._enrollSession.data.InitialOrdersSubItems = '';
              const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                  return item.ParentId && item.ParentId !== undefined;
              });

              this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
              const subTotal = this._enrollSession.data.InitialOrdersSubTotal + this._enrollSession.data.Volume109;
              this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                  return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
              });

              let initialOrderItems = _.groupBy(this._enrollSession.data.RetailOrderSummary.InitialOrders, 'ParentId')
                let customInitaiItemArray = [];
                for (let [key, value] of Object.entries(initialOrderItems)) {
                  _.each(value, (item) => {
                    customInitaiItemArray.push(item);
                });
                }
                this._enrollSession.data.RetailOrderSummary.InitialOrders = customInitaiItemArray;

              this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
              setTimeout(() => {
                  if (this._enrollSession.data.RetailOrderSummary.InitialOrderDetails && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal) {
                      this.checkSubTotal.next(this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal);
                  }
              }, 2000)

              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.success('Product Added to Cart.', 'Success');
              }
          }
          else {
              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
              }
          }
      }
      else if (type === 3) {
          this._enrollSession.data.RetailOrderSummary.SmartOrders = this._enrollSession.data.RetailOrderSummary.SmartOrders || [];
          const SmartOrder = _.find(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
              return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
          });
          if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.SmartOrders)) {
              if (!SmartOrder) {
                  this._enrollSession.data.RetailOrderSummary.SmartOrders.push({
                      ItemCode: product?.ItemCode,
                      Description: product?.Description,
                      Quantity: quantity ? quantity : 1,
                      Price: product?.Price,
                      RetailPrice: product?.Other1Price,
                      ShippingPrice: product?.ShippingPrice,
                      TinyImage: product?.TinyPicture,
                      SmallImage: product?.SmallPicture,
                      BV: product?.BusinessVolume,
                      CV: product?.CommissionableVolume,
                      TaxablePrice: product?.TaxablePrice,
                      LongDetail: product?.LongDetail,
                      LongDetail2: product?.LongDetail2,
                      LongDetail3: product?.LongDetail3,
                      LongDetail4: product?.LongDetail4,
                      ParentId: product?.ParentId,
                      IsUSOnly: product?.IsUSOnly ? product?.IsUSOnly : false
                  });
              }
              else {
                SmartOrder.Quantity = SmartOrder.Quantity+1;
              }
              // *******shipping method**********/

              if (this._enrollSession.data.RetailEnroll.ShippingMethod) {// shipping method
                  this.IsCallCalculateOrder = false;
                  this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
              } else {
                  this.finalShippingMethod = '';
              }
              // **********calculate order**********************/

              if (this.IsCallCalculateOrder) {
                  this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
              }
              else {
                  this._enrollSession.data.SmartOrdersSubTotal = 0;
                  this._enrollSession.data.SmartOrderBVTotal = 0;
                  _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {

                      this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
                      this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);

                  });
              }
              const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
                  return item.ParentId !== undefined;
              });

              let smartOrderItems = _.groupBy(this._enrollSession.data.RetailOrderSummary.SmartOrders, 'ParentId')
                let customSmartItemArray = [];
                for (let [key, value] of Object.entries(smartOrderItems)) {
                  _.each(value, (item) => {
                    customSmartItemArray.push(item);
                });
                }
                this._enrollSession.data.RetailOrderSummary.SmartOrders = customSmartItemArray;

              this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
              this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
              localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.success('Product Added to Cart.', 'Success');
              }
          }
          else {
              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
              }
          }
      }
      else if (type === 4) {
          this._enrollSession.data.RetailOrderSummary.AddOnOrders = this._enrollSession.data.RetailOrderSummary.AddOnOrders || [];

          const addonOrder = _.find(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
              return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
          });

          if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.AddOnOrders)) {

              if (!addonOrder) {// set order
                  this._enrollSession.data.RetailOrderSummary.AddOnOrders.push({
                      ItemCode: product?.ItemCode,
                      Description: product?.Description,
                      Quantity: 1,
                      Price: product?.Price,
                      ShippingPrice: product?.ShippingPrice,
                      TinyImage: product?.TinyPicture,
                      SmallImage: product?.SmallPicture,
                      BV: product?.BusinessVolume,
                      CV: product?.CommissionableVolume,
                      TaxablePrice: product?.TaxablePrice,
                      LongDetail: product?.LongDetail,
                      LongDetail2: product?.LongDetail2,
                      LongDetail3: product?.LongDetail3,
                      LongDetail4: product?.LongDetail4,
                      ParentId: product?.ParentId,
                      IsUSOnly: product?.IsUSOnly ? product?.IsUSOnly : false
                  });


              } else {
                  addonOrder.Quantity = addonOrder.Quantity + 1;

              }
              // *******shipping method**********/
              if (this._enrollSession.data.RetailEnroll.ShippingMethod) {// shipping method
                  this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
              } else {
                  this.finalShippingMethod = '';
              }


              // **********calculate order**********************/
              if (this.IsCallCalculateOrder) {
                  this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
              }
              else {
                  this._enrollSession.data.AddOnOrdersSubTotal = 0;
                  this._enrollSession.data.AddOnOrdersQVTotal = 0;
                  _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {

                      this._enrollSession.data.AddOnOrdersSubTotal = this._enrollSession.data.AddOnOrdersSubTotal + (item.Quantity * item.Price);
                      this._enrollSession.data.AddOnOrdersQVTotal = this._enrollSession.data.AddOnOrdersQVTotal + (item.Quantity * item.BV);
                  });
              }

              this._enrollSession.data.AddOnOrdersSubItems = '';
              const filterAddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
                  return item.ParentId && item.ParentId !== undefined;
              });

              this._enrollSession.data.AddOnOrdersSubItems = _.groupBy(filterAddOnOrders, 'ParentId');
              this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal =
                  this._enrollSession.data.AddOnOrdersSubTotal;
              this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.BV =
                  this._enrollSession.data.AddOnOrdersQVTotal;
              this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);


              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.success('AddOn Product Added to Cart.', 'Success');
              }
          }
          else {
              if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
                  this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
              }
          }
      }

  }
  decreaseProductQuantity(product: any, quantity: any, type: any) {
    if (parseInt(quantity, 10) <= 0) {
      return false;
    }
    if (type === 1) {
        var enrollOrderIndex;
        this._enrollSession.data.RetailOrderSummary.Enrollpack = this._enrollSession.data.RetailOrderSummary.Enrollpack || [];
        const enrollOrder = _.find(this._enrollSession.data.RetailOrderSummary.Enrollpack, (item, index) => {
          if(item.ItemCode === product.ItemCode && item.ParentId === product.ParentId) {
            enrollOrderIndex = index;
          }
          return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
        });
        // *******shipping method**********/
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
          this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
          this.finalShippingMethod = '';
        }
        if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.Enrollpack)) {
          if (enrollOrder.Quantity>1) {// decrease order
            enrollOrder.Quantity = enrollOrder.Quantity - 1;
            this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
            if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
              this._toastr.success('Product quantity decreased from cart.', 'Success');
            }
          }
        }
        else {
          if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
            this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
          }
        }
      }
    if (type === 2) {
      var initialOrderIndex;
      this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
      const initialOrder = _.find(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
        if(item.ItemCode === product.ItemCode && item.ParentId === product.ParentId) {
          initialOrderIndex = index;
        }
        return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
      });
      // *******shipping method**********/
      if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
        this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
      } else {
        this.finalShippingMethod = '';
      }
      if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.InitialOrders)) {
        if (initialOrder.Quantity>1) {// decrease order
          initialOrder.Quantity = initialOrder.Quantity - 1;
          this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
          if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
            this._toastr.success('Product quantity decreased from cart.', 'Success');
          }
        }
      }
      else {
        if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
          this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
        }
      }
    }else if (type === 3) {
      this._enrollSession.data.RetailOrderSummary.SmartOrders = this._enrollSession.data.RetailOrderSummary.SmartOrders || [];
      const SmartOrder = _.find(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
        return item.ItemCode === product?.ItemCode && item?.ParentId === product?.ParentId;
      });
       // *******shipping method**********/

      if (this._enrollSession.data.RetailEnroll.ShippingMethod) {// shipping method
        this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
      } else {
        this.finalShippingMethod = '';
      }
      if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.SmartOrders)) {
        if (SmartOrder.Quantity>1) {
          SmartOrder.Quantity = SmartOrder.Quantity - 1;
          this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
          this._toastr.success('Product quantity decreased from cart.', 'Success');
        }
      }
      else {
        if (!product?.ParentId || product?.ParentId === product?.ItemCode) {
          this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
        }
      }
    }
  }

  isItemRestrcited(product: any, selectedItems: any): any {
    let isRestricted = false;
    let filterItem: any = {};
    filterItem = _.find(selectedItems, (item) => {
      return item.ItemCode === product?.ItemCode;
    });
    if (product?.RestrictQty && product?.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product?.RestrictQty)) {
      isRestricted = true;
    }
    return isRestricted;
  }

  OpenModal(type): any {

  }

  // navigation(page: any): any {
  //   if (page === 'Subscriptions') {
  //     const enrollOrder = _.find(this._enrollSession.data.RetailOrderSummary.Enrollpack, (item) => {
  //       return item.ItemCode === '11050-DD' || item.ItemCode === '11050-D';
  //     });

  //     if (!enrollOrder && this._enrollSession.data.customertype !== 22) {
  //       this.OpenModal('navigationModal');
  //     } else {
  //       this._stepperService.SetStepperPage('Subscriptions');
  //       this.Nextbtn('Subscriptions');
  //     }

  //   }
  //   else if (page === 'AddOnProducts') {
  //     this._stepperService.SetStepperPage('AddOnProducts');
  //     this.Nextbtn('AddOnProducts');
  //   }
  //   else if (page === 'checkout') {
  //     const enrollSubscription = this._enrollSession.data.SmartOrderBVTotal >= 50 ? false : true;
  //     if (enrollSubscription && this._enrollSession.data.RetailOrderSummary.SmartOrders.length === 0) {
  //       if (this._enrollSession.data.customertype === 22) {
  //         this.OpenModal('opportunityModal');
  //       }
  //       else {
  //         this.OpenModal('navigationModal');
  //       }
  //     } else if (this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
  //       this.OpenModal('smartShipDateModal');

  //     }
  //   }
  // }
}
