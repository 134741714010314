import { Component, OnInit, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import * as _ from 'lodash';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'app/shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import * as moment from 'moment';
import { API_URL } from 'app/constants';

@Component({
  selector: 'app-review-page',
  templateUrl: './ReviewPage.component.html',
  styleUrls: ['./ReviewPage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewPageComponent implements OnInit {
  backofficeUrl: any;
  cardNum: any;
  userData: any;
  AuthorizeCheckBox: FormControl =  new FormControl('',Validators.requiredTrue);
  @Input() routPath: any;
  dialogRef: any;
  currentRouterPath: any;
  finalShippingMethod: any;
  siteType: any;
  customerDetail: any = '';
  ProductCode: any;
  SmartOrderDate: any;
  LoggedInUser: any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
  SubscriptionProcessType: any;
  processTypeDisplay:any;
  processTypeDisplayAutoOrder:any
  mode = new FormControl('over' as MatDrawerMode);
  subscriptionPanelOpenState: any = false;
  ultLifestyleFlow:any;
  smartOrderDateForLifestyle:any;
  AutoOrderDetails:any;
  isAuthorize:boolean = false;
  creditCardNum: any;
  paymentDetails:any;
  CustomAutoOrderList: any[];
  hasCardNumberCheck:boolean;
  hasSecurityCodeForAmerican:boolean;
  hasSecurityCodeForVDM:boolean;
  tokenError: boolean = false;
  submissionInProgress: boolean;
  isDiscountExist:any;
  totalRetailPrice:any;
  constructor(
    public _enrollSession: EnrollSessionService,
    private _toastr: ToastrService,
    public router: Router,
    public _restService: RestService,
    public _auth: AuthService,
    public _calculateRetailOrderService: CalculateRetailOrderService,
    public _calculateAutoOrder: CalculateAutoOrderService,
    public _sharedMethodService: SharedMethodService,
    private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
    private _calculateRetailSmartOrder: CalculateRetailSmartOrderService
  ) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.siteType = sessionStorage.getItem("siteType");
    this.creditCardNum = localStorage.getItem('PaymentMethodDifferent');
    this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {}
    this.ultLifestyleFlow = sessionStorage.getItem("onUltLifestyleFlow");
    this.AutoOrderDetails = JSON.parse(localStorage.getItem("AutoOrderDetails"));
    this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
  }

  ngOnInit(): void {
    this._enrollSession.editTransformationPack = '';
    if(this._auth.isLoggedIn()){
      var num =  (this.creditCardNum === null || this.creditCardNum === 'true') ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber || this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField : this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField;
    }
    else{
      var num =  this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber;
    }
    this.cardNum = num?.toString().slice(-4);
    var address1 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address1;
    var address2 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 : '';
    var city = this._enrollSession.data.RetailOrderSummary.shippingFormData.City;
    var zipcode = this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode;
    var state = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
    var country = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
    var address = address1 + ' ' + address2 + ', ' + city + ', ' + state + ' ' + zipcode + ' ' + country;
    var that = this;
    this.userData = JSON.parse(this._auth.getUserData());
    this.processTypeDisplay = localStorage.getItem("SubscriptionProcessType") == 'AutoOrderProcessTy1' ? 'Always Ship' : 'Backup Order';
    this.processTypeDisplayAutoOrder = this.AutoOrderDetails?.ProcessType == 0 ? 'Always Ship' : 'Backup Order';
  }

  ClickToBack() {
    this.router.navigate(['/Checkout']);
  }

  //Summary-Cart

  onSubmitApplication() {
    this.getSubmit();
  }
  onClickChange(isCheckout?: boolean) {
    if (this._enrollSession.data.RetailOrderSummary?.customerInfoData?.Password !== "" && !this._auth.isLoggedIn()) {
      if (this._enrollSession.data.RetailOrderSummary?.customerInfoData?.Password !== this._enrollSession.data.RetailOrderSummary?.customerInfoData?.ConfirmPassword) {
        this._toastr.error('Please verify your Password', 'error');
        return;
      }
    }
    if(this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.SmartOrders && this._enrollSession.data.RetailOrderSummary?.SmartOrders?.length > 0){
      if(this.isAuthorize === false && this.AuthorizeCheckBox?.invalid){
        this.validateAllFormFields(this.AuthorizeCheckBox);
        this._toastr.warning('Please select the Authorize Box');
        return;
      }
    }
    if (this.currentRouterPath.includes("Review-Page")) {
      this.onSubmitApplication();
    }
  }
  

  getSubmit(): void {
    console.log("call submit");
    let smartOrderDateDefault = moment().add(1, 'months').format('MM/DD/YYYY');
    this.SmartOrderDate = this._enrollSession.data?.smartOrderDate ?  this._enrollSession.data?.smartOrderDate : this._enrollSession.data?.smartOrderDate || smartOrderDateDefault;
    this.submissionInProgress = true;
    let request: any;
    request =
    {
        'CustomerId': this.LoggedInUser && Object.keys(this.LoggedInUser).length > 0 ? this.LoggedInUser.CustomerID : this._enrollSession.data.customertype === 22 ? 0
            : (this._enrollSession.data.IsUpGrade ? this._enrollSession.data.IsUpGrade : this._enrollSession.data.CustomerId ?
                this._enrollSession.data.CustomerId : 0),
        'AcceptTerms': this._enrollSession.data.RetailEnroll.AgreeTerms ? this._enrollSession.data.RetailEnroll.AgreeTerms : true,
        'Field5': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? 'Influencer' :
            this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ?
                'NonProfit' : this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' ?
                    'Influencer' : this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'BeautyWellnessProfessional' ? 'Beauty/Wellness Professional' : '',
        'IsProfessional': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? false :
            (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'HealthProfessional' &&
                this._enrollSession.data.FileUploaded ? true : false),
        'IsNonprofit': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? false :
            (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ||
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' && this._enrollSession.data.FileUploaded ? true : false),
        'ApplicantAddress': {
            'Address1': this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 : '',
            'Address2': this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 : '',
            'Address3': '',
            'City': this._enrollSession.data.RetailOrderSummary.shippingFormData.City ? this._enrollSession.data.RetailOrderSummary.shippingFormData.City : '',
            'CountryCode': this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode ? this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode : '',
            'State': this._enrollSession.data.RetailOrderSummary.shippingFormData.State ? this._enrollSession.data.RetailOrderSummary.shippingFormData.State : '',
            'Zip': this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode ? this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode : ''
        },
        //'BirthDate': '',
        'Company': '',
        // 'CustomerType': this._enrollSession.data.customertype === 22 ? this._enrollSession.data.customertype : 22,
        'CustomerType': this.customerDetail.CustomerType? this.customerDetail.CustomerType: 22,
        'CustomerWebAlias': this._enrollSession.data.ReferredByGiftCard ? this._enrollSession.data.ReferredByGiftCard : 0,
        'Email': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
        'EnrollerWebAlias': this._enrollSession.data.ReferredBy ? this._enrollSession.data.ReferredBy : '',
        'FirstName': this._enrollSession.data.RetailOrderSummary.customerInfoData.FirstName || '',
        'LastName': this._enrollSession.data.RetailOrderSummary.customerInfoData.LastName || '',
        'Gender': '',
        'BusinessName': this._enrollSession.data.RetailEnroll.BusinessName ? this._enrollSession.data.RetailEnroll.BusinessName : '',
        'TaxId': this._enrollSession.data.RetailEnroll.EINNumber ? this._enrollSession.data.RetailEnroll.EINNumber :
            this._enrollSession.data.RetailEnroll.CommissionSSN,
        'TaxIDType': 'SSN',
        'LanguageCode': 'en',
        'OrderRequest': {
            'CurrencyCode': 'USD',
            'Details': this._enrollSession.data.OrderProductDetalis ? this._enrollSession.data.OrderProductDetalis : '',
            'ShipMethodID': this._enrollSession.data.RetailEnroll.ShippingMethod || this._enrollSession.data.Order_shipMethodIDField,
            'IsShipAmntDiscount': this._enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal >= 250 || ''
        },
        'PartyID': '',
        'Password': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? '' : this._enrollSession.data.RetailOrderSummary.customerInfoData.Password,
        'PriceType' : this.customerDetail.PriceType ?this.customerDetail.PriceType:  this._enrollSession.data.priceType,
        'Payment': {
            'Amount': this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total ? this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total : 0,
            'BillingAddress': {
                'Address1': this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 : '',
                'Address2': this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 : '',
                'Address3': ' ',
                'City': this._enrollSession.data.RetailOrderSummary.billingAddressData.City ? this._enrollSession.data.RetailOrderSummary.billingAddressData.City : '',
                'CountryCode': this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode : '',
                'State': this._enrollSession.data.RetailOrderSummary.billingAddressData.State ? this._enrollSession.data.RetailOrderSummary.billingAddressData.State : '',
                'Zip': this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode : '',
                'FirstName': this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName : '',
                'LastName': this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName : '',
                'BusinessName': ''
            },
            'CardNumber': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber : ''),
            // 'CardNumber': this._auth.isLoggedIn()?(this._enrollSession.data.RetailOrderSummary.creditCardNumberDisplayField?this._enrollSession.data.RetailOrderSummary.creditCardNumberDisplayField:''):(this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber?this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber : ''),
            'CardType': this._enrollSession.data.RetailEnroll.CardType ? this._enrollSession.data.RetailEnroll.CardType : '',
            'CurrencyCode': this._enrollSession.data.RetailEnroll.CurrencyCode ? this._enrollSession.data.RetailEnroll.CurrencyCode : '',
            'ExpirationMonth': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth : ''),
            'ExpirationYear': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear : ''),
            // 'ExpirationMonth': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.ExpirationMonth?this._enrollSession.data.RetailOrderSummary.ExpirationMonth: '') :(this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth ?this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth: ''),
            // 'ExpirationYear': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.expirationYearField ? this._enrollSession.data.RetailOrderSummary.expirationYearField: '') :( this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear : ''),
            'NameOnCard': this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard : '',
            'SavePayment': true,
            'CvcCode': this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode : '',
            'SelectedCc': 1,
            // 'LastFour': this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize.lastFour,
            // 'FirstSix': this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize.firstSix
        },

        'Payment2': {
            'Amount': 0,
            'BillingAddress': {
                'Address1': '',
                'Address2': '',
                'Address3': '',
                'City': '',
                'CountryCode': '',
                'State': '',
                'Zip': ''
            },
            'CardNumber': '',
            'CardType': '',
            'CurrencyCode': '',
            'ExpirationMonth': 0,
            'ExpirationYear': 0,
            'NameOnCard': '',
            'SavedPaymentMethodID': '',
            'SavePayment': '',
            'CvcCode': '',
            'SelectedCc': 1
        },
        'Phone1': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
        'Phone2': '',
        'MobilePhone': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
        'FaxNumber': '',
        'sendSMS': this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
        'sendEmails': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
        'ShippingAddress': {
            'Address1': this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
            'City': this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
            'CountryCode': this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
            'State': this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
            'Zip': this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode,
            'FirstName': this._enrollSession.data.RetailOrderSummary.shippingFormData.firstName,
            'LastName': this._enrollSession.data.RetailOrderSummary.shippingFormData.lastName,
            'BusinessName': '',
            'Address2': this._enrollSession.data.RetailOrderSummary.shippingFormData.address2,
            'Address3': ''
        },

        'SiteId': '',
        'SponsorId': this._enrollSession.data.RetailEnroll.SponsorId ? this._enrollSession.data.RetailEnroll.SponsorId : '',
        'EnrollerId': this._enrollSession.data.RetailEnroll.EnrollerId ? this._enrollSession.data.RetailEnroll.EnrollerId : '',
        'StoreID': '',
        'TextNumber': '',
        'Username': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
        'WebPageItemID': '',
        'WebPageURL': '',
        'DirectDeposit': {
            'NameOnAccount': this._enrollSession.data.RetailEnroll.CommissionNameOnAccount ? this._enrollSession.data.RetailEnroll.CommissionNameOnAccount : '',
            'BankAccountNumber': this._enrollSession.data.RetailEnroll.CommissionAccNo ? this._enrollSession.data.RetailEnroll.CommissionAccNo : '',
            'BankRoutingNumber': this._enrollSession.data.RetailEnroll.CommissionRoutingNo ? this._enrollSession.data.RetailEnroll.CommissionRoutingNo : '',
            'DepositAccountType': '',
            'BankName': this._enrollSession.data.RetailEnroll.CommissionBankName ? this._enrollSession.data.RetailEnroll.CommissionBankName : '',
            'BankAddress': '',
            'BankCity': '',
            'BankState': '',
            'BankZip': '',
            'BankCountry': '',
            'Iban': '',
            'SwiftCode': ''
        },
        'BillingAddressPreference': this._enrollSession.data.RetailEnroll.BillAddress === 'yes' ? 0 : 1,
        'CommissionPayableType': '',
        'CommissionPayableTo': this._enrollSession.data.RetailEnroll.payAbleTo ? this._enrollSession.data.RetailEnroll.payAbleTo : '',
        'HasRequestedOptIn': (this._enrollSession.data.RetailEnroll.ReceiveText || this._enrollSession.data.RetailEnroll.ReceiveEmail) ? true : false,
        'IsNotBackOffice': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? true : false,
        'GiftCardCode': '',
        'UsePrimaryCard': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.paymentTypeData?.primaryPaymentCard == 'true'? true: false) : (this._enrollSession.data.usePrimaryCard ? this._enrollSession.data.usePrimaryCard : ''),
        'SMSOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
        'EmailOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
        "AutoOrderProcessType": this.SubscriptionProcessType,
        "IsNewAddress":  this.LoggedInUser.CustomerID ? true : false,
        "IsLoggedInCustomer": this._auth.isLoggedIn()? true : false,
        "IsTokenEx": true
    };
    if (this._enrollSession.data.SmartOrderProductDetalis && this._enrollSession.data.SmartOrderProductDetalis.length > 0 && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
        request.AutoOrderRequest =
        {
            'AutoOrderCartID': '',
            'AutoShipStartDate': this.SmartOrderDate,
            'AutoshipType': 0,
            'BeginMonth': 0,
            'Details': this._enrollSession.data.SmartOrderProductDetalis,
            'ShipMethodID': this._enrollSession.data.RetailEnroll.ShippingMethod || this._enrollSession.data.Order_shipMethodIDField,
            "AutoshipId": this._auth.isLoggedIn() ? sessionStorage.getItem('AutoOrderID')   : "",
        };
    }
    if ((this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total === 0 &&
        this._enrollSession.data.RetailEnroll.IsNonprofit && this._enrollSession.data.FileUploaded)) {
        request.Payment.BillingAddress = {};
    }
    this._restService.SubmitApplication(request).subscribe((result: any) => {
        this.submissionInProgress = false;
        if (result) {
            this._enrollSession.submitApplicationData = result;
            sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
            this._enrollSession.data.RetailEnroll.orderDetails = result;
            if (result.OrderID > 0) {
                if (this._enrollSession.data.FileUploaded) {
                    this.FunUploadDocument();
                }
                this.router.navigate(['/Confirmation']);
            } else {
                this._toastr.error(result.Result.errorsField, 'Error');

            }
        } else {
            this._toastr.error('Something went wrong please try again', 'Error');

        }
    }, (err) => {
        this.submissionInProgress = false;
        this._toastr.error('Something went wrong please try again', 'Error');

    }, () => {

    });
  }
  getItemPrice(product) {

    var item = _.filter(this._enrollSession.data.RetailOrderProductDetail, (item) => {
      return (item.ParentItemCode == product.ItemCode || !item.ParentItemCode) && (item.ItemCode == product.ItemCode || item.ItemCode == (product.ItemCode + '-P65'));
    });
    return item.length > 0 ? item[0].PriceEach : 0;
  }
  ClickToRedirect(type){
    if(type === 'shippingDetails' || type === 'shippingMethod'){
      this.router.navigate(['/Shipping']);
    }
    else {
      this.router.navigate(['/Checkout']);
    }
  }
  AuthorizeChecked(){
    if(this.isAuthorize === false){
      this.isAuthorize = true;
    }
    else{
      this.isAuthorize = false;
    }
  }
  getDiscountTotal() {
    let Enrollpack = this._enrollSession.data?.RetailOrderSummary?.Enrollpack || [];
    let InitialOrders = this._enrollSession.data?.RetailOrderSummary?.InitialOrders || [];
    this.isDiscountExist = Enrollpack.find(item => item.ItemCode == "7800GC");
    this.totalRetailPrice = 0;
    if (Enrollpack.length > 0) {
        Enrollpack.forEach((item) => {
            if (item.Price > 0) {
                this.totalRetailPrice += item.RetailPrice * item.Quantity;
            }
        });
    }
    if (InitialOrders.length > 0) {
        InitialOrders.forEach((item) => {
            if (item.Price > 0) {
                this.totalRetailPrice += (item.Other1Price || item.RetailPrice) * item.Quantity;
            }
        });
    }
    let subtotal = this._enrollSession.data?.RetailOrderSummary?.InitialOrderDetails?.SubTotal;
    if (this.isDiscountExist) {
        return 50;
    }
    else if (subtotal < this.totalRetailPrice) {
        return this.totalRetailPrice - subtotal;
    }
    else {
        return 0;
    }
}
getDiscountTotalForSmartOrder(){
    let SmartOrders = this._enrollSession.data?.RetailOrderSummary?.SmartOrders || [];
    this.isDiscountExist = SmartOrders.find(item => item.ItemCode == "7800GC");
    this.totalRetailPrice = 0;
    if (SmartOrders.length > 0) {
        SmartOrders.forEach((item) => {
            if (item.Price > 0) {
                this.totalRetailPrice += item.RetailPrice * item.Quantity;
            }
        });
    }
    let subtotal = this._enrollSession.data?.RetailOrderSummary?.SmartOrderDetails?.SubTotal;
    if (this.isDiscountExist) {
        return 50;
    }
    else if (subtotal < this.totalRetailPrice) {
        return this.totalRetailPrice - subtotal;
    }
    else {
        return 0;
    }
}
  validateAllFormFields(formControl: FormControl) {
    const control: any = formControl;
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else {
      this.validateAllFormFields(control);
    }
}
  ngOnDestroy(): void {

  }
  removedOrder(index, Quantity, parentId, orderType) {
    if (orderType == "todayOrder") {
        this.removeInitOrder({
            index: index,
            Quantity: Quantity,
            parentId: parentId,
        });
    }
    if (orderType == "smartOrder") {
        this.removeSmartOrder({
            index: index,
            Quantity: Quantity,
            parentId: parentId,
        });
    }
    if (orderType == "transformation") {
        this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) ? JSON.parse(sessionStorage.getItem('EnrollSession')) : {};
        let bool: boolean = false;
        console.log(this._enrollSession.data.RetailOrderSummary?.Enrollpack)
        if (this._enrollSession.data.RetailOrderSummary?.Enrollpack.length >= 2) {
            this._enrollSession?.data?.RetailOrderSummary?.Enrollpack?.forEach(element => {
                if (element.ItemCode == '7800GC') {
                    bool = true
                }
            });
            if (bool) {
                return
            } else {
                this.removepackOrder({
                    index: index,
                    Quantity: Quantity,
                    parentId: parentId,
                });
            }
        } else {
            this.EditTransformPack();
        }
    }
}

removepackOrder(Value: any): any {
    this._enrollSession.data.CartQuantity =
        this._enrollSession.data.CartQuantity - Value.Quantity;
    for (
        let arrIndex = 0;
        arrIndex <
        this._enrollSession.data.RetailOrderSummary.Enrollpack.length;
        arrIndex++
    ) {
        if (
            (!this._enrollSession.data.RetailOrderSummary.Enrollpack[
                arrIndex
            ].ParentId ||
                this._enrollSession.data.RetailOrderSummary.Enrollpack[
                    arrIndex
                ].ParentId === Value.parentId) &&
            this._enrollSession.data.RetailOrderSummary.Enrollpack[arrIndex]
                .ItemCode === Value.parentId
        ) {
            this._enrollSession.data.RetailOrderSummary.Enrollpack.splice(
                arrIndex,
                1
            );
        }
    }

    this._enrollSession.data.RetailOrderSummary.Enrollpack = _.filter(
        this._enrollSession.data.RetailOrderSummary.Enrollpack,
        (item) => {
            return item.ParentId !== Value.parentId;
        }
    );
    const filterResult = _.filter(
        this._enrollSession.data.RetailOrderSummary.Enrollpack,
        (item) => {
            return item.ParentId !== undefined;
        }
    );
    this._enrollSession.data.EnrollpackSubItems = _.groupBy(
        filterResult,
        "ParentId"
    );

    this._enrollSession.data.EnrollpackSubTotal = 0;
    this._enrollSession.data.EnrollpackBVTotal = 0;
    _.each(
        this._enrollSession.data.RetailOrderSummary.Enrollpack,
        (item) => {
            this._enrollSession.data.EnrollpackSubTotal =
                this._enrollSession.data?.EnrollpackSubTotal +
                item.Quantity * item.Price;
            this._enrollSession.data.EnrollpackBVTotal =
                this._enrollSession.data?.EnrollpackBVTotal +
                item.Quantity * item.BV;
        }
    );
    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
        this.finalShippingMethod =
            this._enrollSession.data.RetailEnroll.ShippingMethod;
    } else {
        this.finalShippingMethod = "";
    }
    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
}

// ****removeSmartOrder****/
removeSmartOrder(Value: any): any {
    this._enrollSession.data.CartQuantity =
        this._enrollSession.data.CartQuantity - Value.Quantity;
    for (
        let arrIndex = 0;
        arrIndex <
        this._enrollSession.data.RetailOrderSummary.SmartOrders.length;
        arrIndex++
    ) {
        if (
            (!this._enrollSession.data.RetailOrderSummary.SmartOrders[
                arrIndex
            ].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[
                    arrIndex
                ].ParentId === Value.parentId) &&
            this._enrollSession.data.RetailOrderSummary.SmartOrders[
                arrIndex
            ].ItemCode === Value.parentId
        ) {
            this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(
                arrIndex,
                1
            );
        }
    }

    this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(
        this._enrollSession.data.RetailOrderSummary.SmartOrders,
        (item) => {
            return item.ParentId !== Value.parentId;
        }
    );
    const filterResult = _.filter(
        this._enrollSession.data.RetailOrderSummary.SmartOrders,
        (item) => {
            return item.ParentId !== undefined;
        }
    );
    this._enrollSession.data.SmartOrderSubItems = _.groupBy(
        filterResult,
        "ParentId"
    );

    this._enrollSession.data.SmartOrdersSubTotal = 0;
    this._enrollSession.data.SmartOrderBVTotal = 0;
    _.each(
        this._enrollSession.data.RetailOrderSummary.SmartOrders,
        (item) => {
            this._enrollSession.data.SmartOrdersSubTotal =
                this._enrollSession.data.SmartOrdersSubTotal +
                item.Quantity * item.Price;
            this._enrollSession.data.SmartOrderBVTotal =
                this._enrollSession.data.SmartOrderBVTotal +
                item.Quantity * item.BV;
        }
    );
    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
        this.finalShippingMethod =
            this._enrollSession.data.RetailEnroll.ShippingMethod;
    } else {
        this.finalShippingMethod = "";
    }
    this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
}

// ****removeInitOrder****/
removeInitOrder(Value: any): any {
    this._enrollSession.data.CartQuantity =
        this._enrollSession.data.CartQuantity - Value.Quantity;
    for (
        let arrIndex = 0;
        arrIndex <
        this._enrollSession.data.RetailOrderSummary.InitialOrders.length;
        arrIndex++
    ) {
        if (
            (!this._enrollSession.data.RetailOrderSummary.InitialOrders[
                arrIndex
            ].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[
                    arrIndex
                ].ParentId === Value.parentId) &&
            this._enrollSession.data.RetailOrderSummary.InitialOrders[
                arrIndex
            ].ItemCode === Value.parentId
        ) {
            this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(
                arrIndex,
                1
            );
        }
    }
    this._enrollSession.data.InitialOrdersSubTotal = 0;
    this._enrollSession.data.InitialOrdersQVTotal = 0;
    _.each(
        this._enrollSession.data.RetailOrderSummary.InitialOrders,
        (item) => {
            this._enrollSession.data.InitialOrdersSubTotal =
                this._enrollSession.data.InitialOrdersSubTotal +
                item.Quantity * item.Price;
            this._enrollSession.data.InitialOrdersQVTotal =
                this._enrollSession.data.InitialOrdersQVTotal +
                item.Quantity * item.BV;
        }
    );
    this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(
        this._enrollSession.data.RetailOrderSummary.InitialOrders,
        (item) => {
            return item.ParentId !== Value.parentId;
        }
    );
    this._enrollSession.data.TotalItemLength = _.filter(
        this._enrollSession.data.RetailOrderSummary.InitialOrders,
        (item) => {
            return (
                !item.ParentId ||
                parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10)
            );
        }
    );
    const filterInitialOrders = _.filter(
        this._enrollSession.data.RetailOrderSummary.InitialOrders,
        (item) => {
            return item.ParentId && item.ParentId !== undefined;
        }
    );

    this._enrollSession.data.InitialOrdersSubItems = "";
    this._enrollSession.data.InitialOrdersSubItems = _.groupBy(
        filterInitialOrders,
        "ParentId"
    );
    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
        this.finalShippingMethod =
            this._enrollSession.data.RetailEnroll.ShippingMethod;
    } else {
        this.finalShippingMethod = "";
    }
    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
}
EditTransformPack() {
  this._enrollSession.editTransformationPack = "true";
  this.router.navigate(["/TodaysOrder"]);
}
  FunUploadDocument(): void {

        const file = new FormData();


        const byteString = atob(this._enrollSession.data.FileData.split(',')[1]);

        const ab = new ArrayBuffer(byteString.length);

        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);

        }
        const type = this._enrollSession.data.FileName.substring(this._enrollSession.data.FileName.lastIndexOf('.') + 1);

        const bb = new Blob([ab], { type: type });

        file.append('FileName', this._enrollSession.data.FileName);
        file.append('CustomerId', this._enrollSession.data.RetailEnroll.orderDetails.CustomerID);
        file.append('Image', bb);

        this._enrollSession.data.FileData = file;

        const request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                if (request.status === 200) {

                }
            }
        };
        let requestWebServiceUrl = '';
        requestWebServiceUrl = API_URL + 'api/NonProfitEnrollment/UploadFile';
        request.open('POST', requestWebServiceUrl, true);
        request.setRequestHeader('Accept', 'application/json');


        request.send(file);
    }
    validateCardNumber(cardNumber?: any) {
        let length = cardNumber?.toString().length;
        let firstdigit = getDigit(cardNumber,length);
        if((firstdigit === 3 || firstdigit === 4 || firstdigit === 5 || firstdigit === 6)){
            this.hasCardNumberCheck = true;
        }
        else{
            this.hasCardNumberCheck = false;
        }
        this.validateSecurityCode(firstdigit);
    }
    validateSecurityCode(firstdigit?:any){
        if(firstdigit === 3){
            this.hasSecurityCodeForAmerican = true;
            this.hasSecurityCodeForVDM = false;
        }
        else if(firstdigit === 4 || firstdigit === 5 || firstdigit === 6){
            this.hasSecurityCodeForVDM = true;
            this.hasSecurityCodeForAmerican = false;
        }
        else{
            this.hasSecurityCodeForVDM = false;
            this.hasSecurityCodeForAmerican = false;
        }
    }
}
function getDigit(number, n) {
  return Math.floor((number / Math.pow(10, n - 1)) % 10);
}
