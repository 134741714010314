import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { _ROUTER_CONFIG } from '@ngrx/router-store/src/router_store_module';
import * as _ from 'underscore';
import { EnrollSessionService } from './shared/enrollSession.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SharedMethodService } from './shared/sharedMethod.service';
import { RestService } from './shared/rest.service';
import { AuthService } from './shared/auth.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { CookieComponent } from './shared/cookie-dialog/cookie-dialog.component';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    templateUrl: any;
    screenWidth: any;
    mobileView: any;
    mediumScreen: any;
    dialogRef: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    orderList: any[] = [];
    autoOrderList: any[] = [];
    customerDetail: any;
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        // private _fuseConfigService: FuseConfigService,
        // private _fuseNavigationService: FuseNavigationService,
        // private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private ref: ChangeDetectorRef,
        private _restService: RestService,
        public _sharedMethodService: SharedMethodService,
        public _enrollSession: EnrollSessionService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public _auth:AuthService,
        private _matDialog: MatDialog,

    ) {
        // this._translateService.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        // this._translateService.use('en');

        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        setInterval(() => {
            sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
            sessionStorage.setItem('editTransformationPack', this._enrollSession.editTransformationPack);
            // require view to be updated
            this.ref.markForCheck();
        }, 1000);
    }


    ngOnInit(): void {
        this.onResize();
        this.openCookiePopup();
        this.linkBuilderProduct_AddtoCart();
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        if(this._auth.isLoggedIn()) {
            // if (sessionStorage.getItem('newCustomer') != 'true') {
                this._restService.GetAutoOrders(this.customerDetail.CustomerID).subscribe((res: any) => {
                  if (res.AutoOrderList.length > 0) {
                    let CustomAutoOrderList = [];
                    res.AutoOrderList.forEach(item => {
                      if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                        CustomAutoOrderList.push(item);
                      }
                    });
                  
                    var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                    CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                      return (item.AutoOrderID == minAutoOrderId);
                    });
                    
                    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                    this._enrollSession.data.RetailEnroll.ShippingMethod = CustomAutoOrderList[0].ShipMethodID;
                    
                    CustomAutoOrderList[0].Details.forEach(Customdetail => {
                      if (!Customdetail.ParentItemCode) {
                        const itemObj = {
                          ItemCode: Customdetail.ItemCode,
                          Description: Customdetail.Description,
                          Quantity: Customdetail.Quantity,
                          Price: Customdetail.PriceTotal,
                          BusinessVolume: Customdetail.BusinesVolume,
                          ParentId: Customdetail.ItemCode,
                          SmallPicture: Customdetail.SmallPicture
                        };
                        this._enrollSession.data.smartOrderDate = moment(CustomAutoOrderList[0].NextRunDate).format('MM/DD/YYYY');
                        this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                        localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
                      }
                      else {
                        const itemObj = {
                          ItemCode: Customdetail.ItemCode,
                          Description: Customdetail.Description,
                          Quantity: Customdetail.Quantity,
                          Price: Customdetail.PriceTotal,
                          BusinessVolume: Customdetail.BusinesVolume,
                          ParentId: Customdetail.ParentItemCode,
                          SmallPicture: Customdetail.SmallPicture
                        };
                        this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                        localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
                      }
                    });
                  }
                }, error => {
                  console.log("In error")
                })
            //   }
        }
        // Subscribe to config changes
        // this._fuseConfigService.config
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((config) => {

        //         this.fuseConfig = config;

        //         // Boxed
        //         if (this.fuseConfig.layout.width === 'boxed') {
        //             this.document.body.classList.add('boxed');
        //         }
        //         else {
        //             this.document.body.classList.remove('boxed');
        //         }

        //         // Color theme - Use normal for loop for IE11 compatibility
        //         for (const row of this.document.body.classList) {
        //             const className = row;

        //             if (className.startsWith('theme-')) {
        //                 this.document.body.classList.remove(className);
        //             }
        //         }

        //         this.document.body.classList.add(this.fuseConfig.colorTheme);
        //     });    
        // this.$watch('EnrollSession', function (new_value, old_value) {
        //     if (new_value !== old_value) {
        //         sessionStorage.setItem('EnrollSession', JSON.stringify(this.EnrollSession));
        //     }
        // }, true);

        // logCurrent = function(): any {
        //     $log.debug(this.EnrollSession);
        // };
    }
    
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth < 550) {
            this.mobileView = true;
        }
        else {
            this.mobileView = false;
        }
        if (this.screenWidth < 1100) {
            this.mediumScreen = true;
        }
        else {
            this.mediumScreen = false;
        }
        localStorage.setItem('mobileView', this.mobileView)
        // sessionStorage.setItem('mobileView', this.mobileView)
        this._enrollSession.mobileView = this.mobileView;
        sessionStorage.setItem('MobileView', JSON.stringify(this._enrollSession.mobileView));
        this._enrollSession.mediumScreen = this.mediumScreen;
        sessionStorage.setItem('mediumScreen', JSON.stringify(this._enrollSession.mediumScreen));
    }


    private linkBuilderProduct_AddtoCart() {
        this.activatedRoute.queryParamMap
            .pipe(skip(1))
            .subscribe((params: ParamMap) => {
                if (params.get('products')) {
                    //let parameters = event.snapshot.queryParams;
                    var products = params.get('products');
                    if (products) {
                        this.getAllTypeProd().then((items) => {
                            JSON.parse(products).forEach((queryItem) => {
                                _.each(queryItem.orderqty > 0 && items[0], (item) => {
                                    if ((item.ItemCode == queryItem.itemcode)) {
                                        this._sharedMethodService.ContinueRetailOrder(item, queryItem.orderqty, 2)
                                    }
                                })
                                _.each(queryItem.autoshipqty > 0 && items[1], (item) => {
                                    if ((item.ItemCode == queryItem.itemcode)) {
                                        this._sharedMethodService.ContinueRetailOrder(item, queryItem.autoshipqty, 3)
                                    }
                                })
                            });
                        });
                    }
                }
            });
    }

    getAllTypeProd(): Promise<any> {
        const orderpromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 472,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': false
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.orderList = res.Items;
                    resolve(this.orderList);
                }
            }, error => {
                reject(this.orderList);
            });
        });

        const autopromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': '',
                'WebCategoryId': 472,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': true
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.autoOrderList = res.Items;
                    resolve(this.autoOrderList);
                }
            }, error => {
                reject(this.autoOrderList);
            });
        });
        const res = Promise.all([orderpromise, autopromise]);
        return res;
    }

    /**
     * On destroy
     */

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        // this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    onActivate(event) {
        window.scroll({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
         });
     }
     private openCookiePopup()
    {
        if (false) {
            this.dialogRef = this._matDialog.open(CookieComponent, {
                width: '550px',
                maxWidth: '550px',
                height: '456px',
                panelClass: '',
                data: {

                },
                disableClose: true
            });
        }
    }
}
