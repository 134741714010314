import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
    OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CalculateAutoOrderService } from "app/shared/calculateAutoOrder.service";
import { EnrollSessionService } from "app/shared/enrollSession.service";
import * as _ from "underscore";
import { SubscribeDialogComponent } from "app/shared/subscribe-dialog/subscribe-dialog.component";
import { GetDiscountedItemsService } from "app/shared/getDiscountedItems.service";
import { CalculateRetailInitialOrderService } from "app/shared/calculateRetailInitialOrder.service";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { SharedMethodService } from "app/shared/sharedMethod.service";
import { CalculateRetailSmartOrderService } from "app/shared/CalculateRetailSmartOrder.service";
import { takeUntil } from "rxjs/operators";
import { CalculateRetailOrderService } from "app/shared/calculateRetailOrder.service";
import { AuthService } from "app/shared/auth.service";
import * as moment from "moment";
import { RgetRegexService } from "app/shared/getRegex.service";

@Component({
    selector: "summary-cart",
    templateUrl: "./summary-cart.component.html",
    styleUrls: ["./summary-cart.component.scss"],
})
export class SummaryCartComponent implements OnInit, OnDestroy {
    @Input() routPath: any;
    @Input() customerInfoForm: any;
    @Input() shippingForm: any;
    @Input() shippingMethodForm: any;
    @Input() referredForm: any;
    @Input() billingAddressForm: any;
    @Input() paymentTypeForm: any;
    @Input() subscribeDialogShow: any;
    @Output() submitApplication = new EventEmitter<any>();
    dialogRef: any;
    currentRouterPath: any;
    finalShippingMethod: any;
    siteType: any;
    loggedInCustomerInfo: any;
    smartOrderDateForLifestyle: any;
    private _unsubscribeAll: Subject<any>;
    invalidzip1:any;
    constructor(
        private _toastr: ToastrService,
        private _matDialog: MatDialog,
        private router: Router,
        public _enrollSession: EnrollSessionService,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        public _sharedMethodService: SharedMethodService,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        private ref: ChangeDetectorRef,
        public _auth: AuthService,
        public _RgetRegexService: RgetRegexService
    ) {
        if (this._enrollSession.data.RetailOrderSummary == null) {
            this.router.navigate(["/TodaysOrder"], {
                queryParamsHandling: "preserve",
            });
        }
        this._unsubscribeAll = new Subject();
        this.finalShippingMethod = this.finalShippingMethod || 0;
        this.siteType = sessionStorage.getItem("siteType");
        this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
        this._enrollSession.data.smartOrderDate = this._enrollSession.data?.smartOrderDate ? this._enrollSession.data.smartOrderDate : this.smartOrderDateForLifestyle;
    }

    ngOnInit(): void {
        this._calculateRetailOrderService.cartSubtotal = 0;
        var cartSubTotal = 0;
        _.each(
            this._enrollSession.data?.RetailOrderSummary?.InitialOrders,
            function (item) {
                if (item.ItemCode !== "7800GC") {
                    cartSubTotal +=
                        parseFloat(item.RetailPrice) *
                        parseInt(item.Quantity, 10);
                }
            }
        );
        _.each(
            this._enrollSession.data.RetailOrderSummary.Enrollpack,
            function (item) {
                if (item.ItemCode !== "7800GC") {
                    cartSubTotal +=
                        parseFloat(item.RetailPrice) *
                        parseInt(item.Quantity, 10);
                }
            }
        );
        this._calculateRetailOrderService.cartSubtotal = cartSubTotal;
        this.currentRouterPath = this.router.url.split("?")[0];
        this._sharedMethodService.checkSubTotal
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                if (res) {
                    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                        this.finalShippingMethod =
                            this._enrollSession.data.RetailEnroll.ShippingMethod;
                    } else {
                        this.finalShippingMethod = "";
                    }
                    this._calculateRetailInitialOrder.calculate(
                        this.finalShippingMethod
                    );
                }
            });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    subscribeDialog() {
        if (
            this.currentRouterPath == "/Subscribe" &&
            this._enrollSession.data.RetailOrderSummary &&
            this._enrollSession.data.RetailOrderSummary.SmartOrders &&
            this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0
        ) {
            this.onClickChange();
            //this.router.navigate(["/", this.routPath]);
        } else if (this.currentRouterPath == "/Subscribe") {
            this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
                width: "500px",
                maxWidth: "500px",
                height: "356px",
                panelClass: "",
                disableClose: true,
            });
            this.dialogRef.afterClosed().subscribe((response) => {
                if (!response) {
                    return;
                } else {
                    if (response == "false") {
                        this.onClickChange();
                        this.router.navigate(["/", this.routPath]);
                    }
                    if (response == "true") {
                        return;
                    }
                }
            });
        }
    }
    onSubmitApplication() {
        this.submitApplication.emit();
    }
    onClickChange() {
        this.loggedInCustomerInfo = JSON.parse(localStorage.getItem('LoggedInUserData'))
        if (sessionStorage.getItem("siteType") == 'cbd' && this._auth.isLoggedIn() && this.loggedInCustomerInfo.MainCountry == 'CA') {
            this._toastr.error("You cannot create an order from CANADA", "error");
            return;
        }
        if (
            this.customerInfoForm ||
            this.shippingForm ||
            this.shippingMethodForm
        ) {
            if (
                this.shippingForm &&
                this._enrollSession.data.RetailOrderSummary.shippingFormData
                    .address2 == undefined
            ) {
                this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 =
                    "";
            }
            if (
                this._auth.isLoggedIn()
                    ? this.customerInfoForm.valid
                    : this.customerInfoForm.invalid ||
                    this.shippingForm.invalid ||
                    this.shippingMethodForm.invalid
            ) {
                if (this.customerInfoForm.invalid) {
                    this.validateAllFormFields(this.customerInfoForm);
                }
                if (this.shippingForm.invalid) {
                    if (this.shippingForm.get('State').value == -1) {
                        this._enrollSession.addValidators(this.shippingForm, "State");
                        this.shippingForm.controls['State'].setErrors({ required: true });
                    }
                    this.validateAllFormFields(this.shippingForm);
                }
                if (this.shippingMethodForm.invalid) {
                    this.validateAllFormFields(this.shippingMethodForm);
                }
                this._toastr.error("Please fill all required fields", "error");
                return;
            }
        }

        if (
            this.referredForm ||
            this.billingAddressForm ||
            this.paymentTypeForm
        ) {
            if (
                this.billingAddressForm &&
                this._enrollSession.data.RetailOrderSummary.billingAddressData
                    .address2 == undefined
            ) {
                this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 =
                    "";
            }
            if (
                this.referredForm.invalid ||
                this.billingAddressForm.invalid ||
                this.paymentTypeForm.invalid
            ) {
                if (
                    this._auth.isLoggedIn()
                        ? this.referredForm.valid
                        : this.referredForm.invalid
                ) {
                    this.validateAllFormFields(this.referredForm);
                    return this._toastr.error(
                        "Please fill the referral code",
                        "error"
                    );
                }
                if (this.billingAddressForm.invalid) {
                    this.validateAllFormFields(this.billingAddressForm);
                    return this._toastr.error(
                        "Please fill all required fields",
                        "error"
                    );
                }
                if (this._auth.isLoggedIn() ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard === "true" ? this.paymentTypeForm.valid : this.paymentTypeForm.invalid : this.paymentTypeForm.invalid) {
                    this.validateAllFormFields(this.paymentTypeForm);
                    return this._toastr.error(
                        "Please fill all required fields",
                        "error"
                    );
                }
            }
        }
        if(this.currentRouterPath.includes("Shipping")){
            this.regexValidateZip(this._enrollSession.data.RetailOrderSummary?.shippingFormData?.ZipCode);
            if(this.invalidzip1){
                return this._toastr.error('Please check your zipcode.','error');
            }
        }

        if (
            this.currentRouterPath == "/Shipping" &&
            this._enrollSession.data.RetailOrderSummary &&
            this._enrollSession.data.RetailOrderSummary.SmartOrders &&
            this._enrollSession.data.RetailOrderSummary.SmartOrders.length >
            0 &&
            (this._enrollSession.data.smartOrderDate == undefined ||
                this._enrollSession.data.smartOrderDate == null ||
                this._enrollSession.data.smartOrderDate == "")
        ) {
            this._toastr.error("Please select Smart Order Date", "error");
            return;
        }

        //  if (this.routPath != "" && this.routPath != null && this.routPath != "Shipping") {
        //     this.router.navigate(['/', this.routPath], {
        //         queryParamsHandling: 'preserve'
        //       });
        // }

        if (
            this._enrollSession.data.RetailOrderSummary.Enrollpack &&
            this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0
        ) {
            if (this.siteType == "cbd") {
                this._toastr.warning("Please select CBD+", "Warning");
            } else {
                this._toastr.warning("Please select Cante Pack", "Warning");
            }

            this.router?.navigate(["/TodaysOrder"], {
                queryParamsHandling: "preserve",
            });
        } else if (this.routPath != "" && this.routPath != null) {
            this.router?.navigate(["/", this.routPath], {
                queryParamsHandling: "preserve",
            });
        }
    }
    removedOrder(index, Quantity, parentId, orderType) {
        if (orderType == "todayOrder") {
            this.removeInitOrder({
                index: index,
                Quantity: Quantity,
                parentId: parentId,
            });
        }
        if (orderType == "smartOrder") {
            this.removeSmartOrder({
                index: index,
                Quantity: Quantity,
                parentId: parentId,
            });
        }
        if (orderType == "transformation") {
            this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) ? JSON.parse(sessionStorage.getItem('EnrollSession')) : {};
            let bool: boolean = false;
            console.log(this._enrollSession.data.RetailOrderSummary?.Enrollpack)
            if (this._enrollSession.data.RetailOrderSummary?.Enrollpack.length >= 2) {
                this._enrollSession?.data?.RetailOrderSummary?.Enrollpack?.forEach(element => {
                    if (element.ItemCode == '7800GC') {
                        bool = true
                    }
                });
                if (bool) {
                    return
                } else {
                    this.removepackOrder({
                        index: index,
                        Quantity: Quantity,
                        parentId: parentId,
                    });
                }
            } else {
                this.EditTransformPack();
            }
        }
    }

    removepackOrder(Value: any): any {
        this._enrollSession.data.CartQuantity =
            this._enrollSession.data.CartQuantity - Value.Quantity;
        for (
            let arrIndex = 0;
            arrIndex <
            this._enrollSession.data.RetailOrderSummary.Enrollpack.length;
            arrIndex++
        ) {
            if (
                (!this._enrollSession.data.RetailOrderSummary.Enrollpack[
                    arrIndex
                ].ParentId ||
                    this._enrollSession.data.RetailOrderSummary.Enrollpack[
                        arrIndex
                    ].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.Enrollpack[arrIndex]
                    .ItemCode === Value.parentId
            ) {
                this._enrollSession.data.RetailOrderSummary.Enrollpack.splice(
                    arrIndex,
                    1
                );
            }
        }

        this._enrollSession.data.RetailOrderSummary.Enrollpack = _.filter(
            this._enrollSession.data.RetailOrderSummary.Enrollpack,
            (item) => {
                return item.ParentId !== Value.parentId;
            }
        );
        const filterResult = _.filter(
            this._enrollSession.data.RetailOrderSummary.Enrollpack,
            (item) => {
                return item.ParentId !== undefined;
            }
        );
        this._enrollSession.data.EnrollpackSubItems = _.groupBy(
            filterResult,
            "ParentId"
        );

        this._enrollSession.data.EnrollpackSubTotal = 0;
        this._enrollSession.data.EnrollpackBVTotal = 0;
        _.each(
            this._enrollSession.data.RetailOrderSummary.Enrollpack,
            (item) => {
                this._enrollSession.data.EnrollpackSubTotal =
                    this._enrollSession.data?.EnrollpackSubTotal +
                    item.Quantity * item.Price;
                this._enrollSession.data.EnrollpackBVTotal =
                    this._enrollSession.data?.EnrollpackBVTotal +
                    item.Quantity * item.BV;
            }
        );
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod =
                this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = "";
        }
        this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
    }

    // ****removeSmartOrder****/
    removeSmartOrder(Value: any): any {
        this._enrollSession.data.CartQuantity =
            this._enrollSession.data.CartQuantity - Value.Quantity;
        for (
            let arrIndex = 0;
            arrIndex <
            this._enrollSession.data.RetailOrderSummary.SmartOrders.length;
            arrIndex++
        ) {
            if (
                (!this._enrollSession.data.RetailOrderSummary.SmartOrders[
                    arrIndex
                ].ParentId ||
                    this._enrollSession.data.RetailOrderSummary.SmartOrders[
                        arrIndex
                    ].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.SmartOrders[
                    arrIndex
                ].ItemCode === Value.parentId
            ) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(
                    arrIndex,
                    1
                );
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(
            this._enrollSession.data.RetailOrderSummary.SmartOrders,
            (item) => {
                return item.ParentId !== Value.parentId;
            }
        );
        const filterResult = _.filter(
            this._enrollSession.data.RetailOrderSummary.SmartOrders,
            (item) => {
                return item.ParentId !== undefined;
            }
        );
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(
            filterResult,
            "ParentId"
        );

        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(
            this._enrollSession.data.RetailOrderSummary.SmartOrders,
            (item) => {
                this._enrollSession.data.SmartOrdersSubTotal =
                    this._enrollSession.data.SmartOrdersSubTotal +
                    item.Quantity * item.Price;
                this._enrollSession.data.SmartOrderBVTotal =
                    this._enrollSession.data.SmartOrderBVTotal +
                    item.Quantity * item.BV;
            }
        );
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod =
                this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = "";
        }
        this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
    }

    // ****removeInitOrder****/
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity =
            this._enrollSession.data.CartQuantity - Value.Quantity;
        for (
            let arrIndex = 0;
            arrIndex <
            this._enrollSession.data.RetailOrderSummary.InitialOrders.length;
            arrIndex++
        ) {
            if (
                (!this._enrollSession.data.RetailOrderSummary.InitialOrders[
                    arrIndex
                ].ParentId ||
                    this._enrollSession.data.RetailOrderSummary.InitialOrders[
                        arrIndex
                    ].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.InitialOrders[
                    arrIndex
                ].ItemCode === Value.parentId
            ) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(
                    arrIndex,
                    1
                );
            }
        }
        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(
            this._enrollSession.data.RetailOrderSummary.InitialOrders,
            (item) => {
                this._enrollSession.data.InitialOrdersSubTotal =
                    this._enrollSession.data.InitialOrdersSubTotal +
                    item.Quantity * item.Price;
                this._enrollSession.data.InitialOrdersQVTotal =
                    this._enrollSession.data.InitialOrdersQVTotal +
                    item.Quantity * item.BV;
            }
        );
        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(
            this._enrollSession.data.RetailOrderSummary.InitialOrders,
            (item) => {
                return item.ParentId !== Value.parentId;
            }
        );
        this._enrollSession.data.TotalItemLength = _.filter(
            this._enrollSession.data.RetailOrderSummary.InitialOrders,
            (item) => {
                return (
                    !item.ParentId ||
                    parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10)
                );
            }
        );
        const filterInitialOrders = _.filter(
            this._enrollSession.data.RetailOrderSummary.InitialOrders,
            (item) => {
                return item.ParentId && item.ParentId !== undefined;
            }
        );

        this._enrollSession.data.InitialOrdersSubItems = "";
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(
            filterInitialOrders,
            "ParentId"
        );
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod =
                this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = "";
        }
        this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    EditTransformPack() {
        this._enrollSession.editTransformationPack = "true";
        this.router.navigate(["/TodaysOrder"]);
    }
    ngDoCheck() {
        this.ref.markForCheck();
    }
    p65ProductsFind(){
        return this._enrollSession.data?.RetailOrderProductDetail?.find(productCode => {
            return productCode.ItemCode?.includes('-P65');
        });
    }
      checkwarningStatement(){
        return ((this.currentRouterPath !== '/Confirmation') && 
        ((this._enrollSession?.data?.RetailOrderSummary && this._enrollSession?.data?.RetailOrderSummary?.shippingFormData && this._enrollSession?.data?.RetailOrderSummary?.shippingFormData?.State === 'CA')) 
        && this.p65ProductsFind());
    }
    regexValidateZip(zipCode): void {
        const shippingForm = this.shippingForm ? this.shippingForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(zipCode)) {
            this.invalidzip1 = false;
        } else if (zipCode && (zipCode.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
}
