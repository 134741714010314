<!-- <div class="dialog-content-wrapper" id="two-way-wrapper" *ngIf="currentRouterPath == '/TodaysOrder'"> -->
<!-- need to open this from header on any page  -->
<div class="dialog-content-wrapper" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div" id="pop-up-div">
        <div class="matIconContainer"><mat-icon (click)="closeDialog()">close</mat-icon>
        </div>
        <div class="pop-up-button-div customer_dialog" *ngIf="!userLoginDialog">
            <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="newCustomer()">NEW
                CUSTOMER</button>&nbsp;&nbsp;
            <button mat-button aria-current="page" class="pop-up-button w-button w--current" cdkFocusInitial
                (click)="returingCustomer()">RETURNING CUSTOMER</button>
        </div>
        <div class="pop-up-login-div"id="login_container" *ngIf="userLoginDialog">
          <h3 class="text-center p-t-20 f-s-24">Customer Login</h3>
          <form [formGroup]="customerLoginForm" name="Login" id="login">
              <div *ngIf="!validateShow" fxLayout="column" class="p-20-15-10">
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="text" class="form-field w-input input-field" maxlength="256"
                                  name="userName" formControlName="userName" placeholder="Email or Login Name"
                                  id="Email" [(ngModel)]="userInfo.userName" required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('userName').valid && customerLoginForm.get('userName').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('userName').errors['required']" class="help-block">This
                                      field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="password" class="form-field w-input"
                                  [(ngModel)]="userInfo.Password" maxlength="256" name="password"
                                  formControlName="password" placeholder="Password" id="password"
                                  required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('password').valid && customerLoginForm.get('password').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('password').errors['required']"
                                      >This field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
              <br>
              <div *ngIf="!validateShow" layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                  fxLayout-xs="column" fxLayout-align="start center"
                  fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                  <button type="submit" class="btn-project w-80 upper-case f-s-18" color="primary"
                      mat-raised-button (click)="SignIn()" [disabled]="appLoader" >SIGN IN</button>
              </div>
              <div *ngIf="validateShow" style="font-size: 18px;width: 420px;margin: 0 auto;">
                Reset password is required for security purposes. Click the reset password link below and a reset password email will be sent to you.
            </div>
              <div layout="row" fxLayout-sm="column" class="m-t-30 login-bottom"
                  fxLayout-xs="column" fxLayoutAlign="center start"
                  fxLayoutAlign-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                  <!-- <a class="forgot-pass cursor-pointer m-t-20" id="forgot-pass"
                  disabled>Forgot Password?</a>|-->
                  <a class="reset-pass cursor-pointer m-t-20" id="" (click)="resetPassword()">Reset Password</a>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>


