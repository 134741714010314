import { Injectable } from '@angular/core';
import { BASE_URL, API_URL, objectToParams } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {


  constructor(private _http: HttpClient) { }

  Delete(resultUrl: string): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;
    var access_token = JSON.parse(localStorage.getItem('authtoken'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json-patch+json; charset=UTF-8',
        'Authorization': "Bearer " + access_token
      })
    };

    return this._http.delete<HttpResponse<object>>(url, httpOptions).pipe();
  }


  Put(resultUrl: string, requestParameterData: any): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;
    var access_token = JSON.parse(localStorage.getItem('authtoken'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + access_token
      })
    };

    return this._http.put<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe();
  }


  Get(resultUrl: string): Observable<HttpResponse<object>> {
    var access_token = JSON.parse(localStorage.getItem('authtoken'));
    const url = API_URL + resultUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': "Bearer " + access_token
      })
    };

    return this._http.get<HttpResponse<object>>(url, httpOptions).pipe(tap());
  }


  Post(resultUrl: string, requestParameterData: any, timeout?: any): Observable<HttpResponse<object>> {
    requestParameterData = objectToParams(requestParameterData);
    var access_token = JSON.parse(localStorage.getItem('authtoken'));
    const url = API_URL + resultUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': "Bearer " + access_token
      })
    };

    return this._http.post<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  PostFile(resultUrl: string, requestParameterData: any, timeout?: any): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  Token(request): Observable<HttpResponse<object>> {
    const url = BASE_URL + '/token';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<object>>(url, request, httpOptions).pipe(tap());
  }

  GetCustomersDetails(request: any): Observable<HttpResponse<object>> {
    const _url = '/RecurringOrders/GetCustomersDetails' + request;
    return this.Get(_url);
  }

  GetCustomers(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetCustomers?CustomerId=' + request;
    return this.Get(Requesturl);
  }

  getProducts(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetOrderItems';
    return this.Post(Requesturl, request);
  }

  GetVerifedAddressResponse(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/Comman/GetVerifedAddressResponse';
    return this.Post(Requesturl, request);
  }

  getMemberShipKit(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetPackItems';
    return this.Post(Requesturl, request);
  }

  GetCountryRegions(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetCountryRegions';
    return this.Post(Requesturl, request);
  }

  ShippingMethod(request: any): Observable<HttpResponse<object>> {
    const Requesturl = 'GetShipMethods' + request;
    return this.Get(Requesturl);
  }

  VerifyUsername(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/VerifyUsername' + request;
    return this.Get(Requesturl);
  }

  VerifyGiftCard(request: any): Observable<HttpResponse<object>> {
    const Requesturl = 'IsValidGiftCard' + request;
    return this.Get(Requesturl);
  }

  GetCustomerBilling(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/getcustomerbilling?customerid=' + request;
    return this.Get(Requesturl);
  }

  VerifyWebalias(request: any): Observable<HttpResponse<object>> {

    const Requesturl = '/NonProfitEnrollment/VerifyWebalias' + request;
    return this.Get(Requesturl);
  }

  SubmitApplication(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/SubmitApplication';
    return this.Post(Requesturl, request);
  }

  IsEmailRegistered(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/IsEmailAvailable' + request;
    return this.Post(Requesturl, '');
  }

  calculateRetailOrder(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/CalculateOrder';
    return this.Post(Requesturl, request);
  }

  GetCustomerSite(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/Comman/GetCustomerSite' + request;
    return this.Get(Requesturl);
  }

  GetItems(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetTestItems' + request;
    return this.Get(Requesturl);
  }
  CreateAutoOrder(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/RecurringOrders/CreateAutoOrder';
    return this.Post(Requesturl, request);
  }
  GetAutoOrders(req: string) {
    var _url = '/RecurringOrders/GetAutoOrders?CustomerID=' + req;
    return this.Get(_url);
  }
  ResetPassword(request: any): Observable<HttpResponse<object>> {
    const _url = '/NonProfitEnrollment/ResetPassword';
    return this.Post(_url, request);
  }
  ValidateResetPasswordToken(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/ValidateResetPasswordToken' + request;
    return this.Get(Requesturl);
  }
  validateCustomer(request): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/ValidateCustomer' + request;
    return this.Get(Requesturl)
  }
  SilentLogin(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/GetSilentLoginToken?customerId=' + request;
    return this.Get(Requesturl);
  }
  GetResetPasswordLink(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/SendResetPasswordLink' + request;
    return this.Get(Requesturl);
  }
}
