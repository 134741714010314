import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { result } from 'underscore';
import { AuthService } from '../auth.service';
import { EnrollSessionService } from '../enrollSession.service';
import { RestService } from '../rest.service';
import { SharedMethodService } from '../sharedMethod.service';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  backofficeUrl: any;
  productList: any[] = [];
  EnhanceTranformation: any[] = [];
  allProductList: any;
  customertype: any;
  userData: any;
  searchedValue: any;
  formControl = new FormControl('');
  siteType: any = false;
  SubscriptionProcessType:any;

  public currentRoute: boolean = false;
  constructor(public _matDialog: MatDialog,
    public _auth: AuthService,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    public _restService: RestService,
    public _sharedMethodService: SharedMethodService,
    public cookieService: CookieService,
    private _activateRoute: ActivatedRoute,
    ) {
    }
    
    ngOnInit(): void {
      this.userData = JSON.parse(this._auth.getUserData());
      setTimeout(() => {
        this.siteType = (this._activateRoute.snapshot.queryParamMap.get('site')=='cbd' || sessionStorage.getItem("siteType")=='cbd' || this._activateRoute.snapshot.queryParamMap.get('site')=='')?true:false;
      }, 100);
      this.getAllProduct(472, 22);
  }
  ngAfterViewInit():void {
    if (this._auth.isLoggedIn()) {
      var backofficeUrl = '';
      this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
        backofficeUrl = res;
      }, error => {
        console.log("In error")
      });
      
    }
    $('#account').on('click', function () {
      window.open(backofficeUrl, "_blank");
    });
  }

  getUserData(customerDetail) {
    if (this._auth.isLoggedIn()) {
      this.customertype = customerDetail.CustomerType || 22;
      this.getAllProduct(472, this.customertype);
    }
  }
  getAllProduct(webCategoryId, customertype) {

    const request = {
      'EnrollmentType': '',
      'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
      'WebCategoryId': webCategoryId,
      'isUpgradeCustomer': false,
      'IsSubscriptionOrder': false,
      'PriceType': this._enrollSession.data.priceType
    };
    this._restService.getProducts(request).subscribe((res: any) => {
      var listData = res.Items;

      var uniqueproductListData = listData.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
      //this.productList = uniqueproductListData;
      this.allProductList = uniqueproductListData;
      //var EnhanceTranformation = [];
      this.allProductList.forEach((item) => {
        if (item.CategoryID === 482) {
          this.EnhanceTranformation.push(item);
        }
        if (item.CategoryID === 495) {
          this.EnhanceTranformation.push(item);
        }
        if (item.CategoryID === 472) {
          this.productList.push(item);          
        }
      });
    });
  }
  login() {
    const dialogRef = this._matDialog.open(UserDialogComponent, {
      width: '500px',
      maxWidth: '500px',
      height: '356px',
      disableClose: false,
      data: {
        header: true,
        linkBuilder_popupLoad: false
      }
    })
    this._sharedMethodService.isOpenDialog = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._sharedMethodService.isOpenDialog = false;
        const customerDetail = JSON.parse(this._auth.getUserData()) || {};
        if (customerDetail.MainCountry=="CA") {
          this._enrollSession.data.RetailOrderSummary = {};
        }
        this.getUserData(customerDetail);
        this._sharedMethodService.userLoggedInEvent.emit(customerDetail);
        // if (sessionStorage.getItem('newCustomer') != 'true') {
        this._restService.GetAutoOrders(customerDetail.CustomerID).subscribe((res: any) => {
          if (res.AutoOrderList.length > 0) {
            let CustomAutoOrderList = [];
            res.AutoOrderList.forEach(item => {
              if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                CustomAutoOrderList.push(item);
              }
            });

            var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
            CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
              return (item.AutoOrderID == minAutoOrderId);
            });
            sessionStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
            sessionStorage.setItem("AutoOrderDetails", JSON.stringify( CustomAutoOrderList[0]));
            this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1": "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
            localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
            this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
            this._enrollSession.data.RetailEnroll.ShippingMethod = CustomAutoOrderList[0].ShipMethodID;

            CustomAutoOrderList[0].Details.forEach(Customdetail => {
              if (!Customdetail.ParentItemCode) {
                const itemObj = {
                  ItemCode: Customdetail.ItemCode,
                  Description: Customdetail.Description,
                  Quantity: Customdetail.Quantity,
                  Price: Customdetail.PriceTotal,
                  BusinessVolume: Customdetail.BusinesVolume,
                  ParentId: Customdetail.ItemCode,
                  SmallPicture: Customdetail.SmallPicture
                };
                this._enrollSession.data.smartOrderDate = moment(CustomAutoOrderList[0].NextRunDate).format('MM/DD/YYYY');
                this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
              }
              else {
                const itemObj = {
                  ItemCode: Customdetail.ItemCode,
                  Description: Customdetail.Description,
                  Quantity: Customdetail.Quantity,
                  Price: Customdetail.PriceTotal,
                  BusinessVolume: Customdetail.BusinesVolume,
                  ParentId: Customdetail.ParentItemCode,
                  SmallPicture: Customdetail.SmallPicture
                };
                this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
              }
            });
          }
        }, error => {
          console.log("In error")
        })
        // }
      }
    });
  }

  redirectToBackoffice(userData) {
    this._restService.SilentLogin(userData.CustomerID).subscribe((res: any) => {
      this.backofficeUrl = res;
      window.open(this.backofficeUrl, "_blank");
    }, error => {
      console.log("In error")
    })
  }

  logout() {
    this.cookieService.delete('access_token', '/');
    this.cookieService.delete('X-Auth', '/');
    this.cookieService.deleteAll('/');
    this._enrollSession.data.IsLoggedIn = false;
    this._enrollSession.data.LoggedInUserData = {};
    this._enrollSession.data.priceType = 0;
    this.router.navigate(['/TodaysOrder'],{ queryParams: {site: "cbd"}}).then(() => {
      if (window.localStorage && window.sessionStorage) {
        localStorage.removeItem('LoggedInUserData');
        window.localStorage.removeItem('user');
        window.sessionStorage.removeItem('EnrollSession')
        window.sessionStorage.clear();
        window.localStorage.clear();
      }
      sessionStorage.setItem("siteType", "cbd");
      window.location.reload();
    });
  }

  toggleCollapsed() {

  }

  redirectToProduct() {
    this.router.navigate(['/TodaysOrder']);
  }

  onSearchChange(event, item) {
    this._sharedMethodService.headerSearchValue = item ? item : (event.target.value);
  }

  selectProdcut(item) {
    this.onSearchChange(event, item);
  }

  getHeaderSearchedValue() {
    return this._sharedMethodService.headerSearchValue;
  }
}
