
<nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background-color: #FCFCF7;z-index:1160 !important;" [ngClass]="{'noClick':_sharedMethodService.isOpenDialog}">
    <div class="container-fluid">
       
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
            <span class="navbar-toggler-icon" ></span>
        </button>
        <a class="navbar-brand" href="/">
            <img src="../../../assets/img/logo-purium.png" alt="" style="width:80px;">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0"
                style="margin-right: 15px !important;margin-left: 10px !important;">
                <!-- <li class="nav-item">
                    <a class="nav-link active" routerLink="/todaysorder" style="color: #567055">Home</a>
                </li> -->
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link active" href="https://ultlifestyle.com" style="color: #567055">Lifestyle
                        Subscription</a>
                </li> -->
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" href="https://ultpack.com" style="color: #567055">Transformation</a>
                </li> -->
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" href="#" style="color: #567055">All Products</a>
                </li> -->
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" href="#" style="color: #567055">Login</a>
                </li> -->
            </ul>
            <!-- Mobile Search -->
            <form class="d-flex mobile-search js-bold">
                <input class="form-control me-2 w-80" type="search" [matAutocomplete]="auto" placeholder="Search" name="searchedValue" [(ngModel)]="searchedValue" aria-label="Search" (input)="onSearchChange($event,'')">
                <mat-autocomplete #auto = "matAutocomplete" class="bg-white">
                    <mat-option *ngFor = "let product of productList | search: 'Description': getHeaderSearchedValue()" [value] = "product.Description" (click)="selectProdcut(product.Description)">
                       {{product.Description}}
                    </mat-option>
                 </mat-autocomplete>
                <button class="btn" type="submit">
                    <img src="assets/icons/magnifying-glass-solid.svg" alt="" style="width:20px;" (click)="redirectToProduct()">
                </button>
            </form>
            <!-- not logged in -->
            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0"
                style="margin-right: 30px !important;margin-left: auto !important;cursor: pointer;"
                *ngIf="!_auth.isLoggedIn()">
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li> -->
                <li class="nav-item" [ngClass]="{'noClick':_sharedMethodService.isOpenDialog}">
                    <a class="nav-link" style="color: #567055" (click)="login()" *ngIf="siteType">Login</a>
                </li>
            </ul>

            <!-- logged in user -->
            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0"
                style="margin-right: 30px !important;margin-left: auto !important;cursor: pointer;"
                *ngIf="_auth.isLoggedIn()">
                <!-- <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" style="color: #567055">Hi Login</a>
                </li>
                <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" style="color: #567055">My Account</a>
                </li>
                <li class="nav-item desktop-search" style="font-size:26px; color:#567055;">•</li>
                <li class="nav-item">
                    <a class="nav-link" style="color: #567055" (click)="logout()">Logout</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" style="color: #567055">Hi {{_enrollSession.data.LoggedInUserData?.FirstName || userData?.FirstName}} | <span class=" cursor-pointer" (click)="logout()">Logout</span></a>
                    <!-- <a class="nav-link" style="color: #567055">Hi {{_enrollSession.data.LoggedInUserData?.FirstName || userData?.FirstName}} | <a id="account"  class="cursor-pointer myaccountText" style="text-decoration: none;color: #567055">My Account</a> | <span class=" cursor-pointer" (click)="logout()">Logout</span></a> -->
                </li>
            </ul>
            <!-- Desktop -->
            <form class="search desktop-search">
                <div class="search__wrapper">
                        <input type="text" id="filterInput" value="searchedValue" placeholder="Search Product..."  name="searchedValue" 
                        [matAutocomplete]="auto" (input)="onSearchChange($event,'')"
                        [formControl]="formControl" class="search__field js-bold" 
                        >
                        <mat-autocomplete #auto = "matAutocomplete" class="bg-white">
                            <mat-option *ngFor = "let product of productList | search: 'Description,ItemCode,ShortDetail,LongDetail': getHeaderSearchedValue()" [value] = "product.Description" (click)="selectProdcut(product.Description)">
                               {{product.Description}}
                            </mat-option>
                         </mat-autocomplete>
                 
                    <button type="submit" class="fa fa-search search__icon" (click)="redirectToProduct()"></button>
                </div>
            </form>
        </div>
    </div>
</nav>

<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">

