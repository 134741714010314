<div class="dialog-content-wrapper" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div" id="pop-up-div">
        <div class="matIconContainer"><mat-icon (click)="closeDialog()">close</mat-icon>
        </div>
        
        <div class="pop-up-login-div" *ngIf="true" id="login_container">
          <form [formGroup]="customerPasswordResetForm" name="Login" id="login">
              <div fxLayout="column" class="p-20-15-10">
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="text" class="form-field w-input input-field" maxlength="256"
                                  name="userName" formControlName="userName" placeholder="Email"
                                  id="Email" [(ngModel)]="userInfo.userName" required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerPasswordResetForm.get('userName').valid && customerPasswordResetForm.get('userName').touched">
                                  <span
                                      *ngIf="customerPasswordResetForm.get('userName').errors['required']">This
                                      field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
              <br>
              <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                  fxLayout-xs="column" fxLayout-align="start center"
                  fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                  <button type="submit" class="btn-project w-80 upper-case f-s-18" color="primary" [ngClass]="{noClick: appLoader}"
                      mat-raised-button (click)="SendLink()">Send Link</button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>


