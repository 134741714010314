import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { any } from 'underscore';

@Component({
    selector: 'Enhance',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Enhance.component.html',
    styleUrls: ['./Enhance.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EnhanceComponent implements OnInit {
    productList: any[] = [];
    routPath: any = "Subscribe";
    EnhanceTranformation: any[] = [];
    onSearchEnhance:  any;
    panelOpenState : any;
    siteType: any;
    constructor(
      
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        public _sharedMethodService: SharedMethodService,
        private _matDialog: MatDialog,
        private router : Router,
        private _toastr: ToastrService
    ) {
        if ((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)) {
            if (this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
                this._toastr.warning("Please select Transformation Pack", 'Warning');
            }
            this._enrollSession.data.RetailOrderSummary = {};
            sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            this.router.navigate(['/TodaysOrder'], {
                queryParamsHandling: 'preserve',
            })
        }
    }

    ngOnInit(): void {
        this._enrollSession.editTransformationPack = "true";
        this.siteType = sessionStorage.getItem("siteType");
        if (this.siteType == 'collagen') {
            this.getAllProduct(495);
            this.getAllProduct(472)
        }
        else 
        if (this.siteType == 'cbd') {
            this.getAllProduct(513);
            this.getAllProduct(472)
        }
        else {
            this.getAllProduct(482);
            this.getAllProduct(472)
        }
    }

    getAllProduct(webCategoryId) {
        const request = {
            'EnrollmentType': '',
            'CustomerType': 22,
            'WebCategoryId': webCategoryId,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': false

        };
        this._restService.getProducts(request).subscribe((res: any) => {
            var listData = res.Items;
            
            var uniqueproductListData = listData.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
            //this.productList = uniqueproductListData;
            var allProductList = uniqueproductListData;
            
            //var EnhanceTranformation = [];
            allProductList.forEach((item) => {
                if (item.CategoryID === 482) {
                    this.EnhanceTranformation.push(item);
                }
                if (item.CategoryID === 495) {
                    this.EnhanceTranformation.push(item);
                }
                if (item.CategoryID === 513) {
                    this.EnhanceTranformation.push(item);
                }
                if (item.CategoryID === 472) {
                    this.productList.push(item);
                    
                }
            });
            //let enhanceTransformProducts = cloneDeep(EnhanceTranformation);
            //enhanceTransformProducts.forEach((item) => {
            //    //if(item.ItemCode ==2520) {
            //    //    item.SmallPicture = "assets/images/enhance-image/immunesupport-2520.png";
            //    //}else if(item.ItemCode ==2750) {
            //    //    item.SmallPicture = "assets/images/enhance-image/energy-boost-2750.png";
            //    //}else if(item.ItemCode ==2752) {
            //    //    item.SmallPicture = "assets/images/enhance-image/digestion-2752.png";
            //    //}else if(item.ItemCode ==2754) {
            //    //    item.SmallPicture = "assets/images/enhance-image/hydration-2754.png";
            //    //}else if(item.ItemCode == 2012) {
            //    //    item.SmallPicture = "assets/images/enhance-image/elimination-2012.png";
            //    //}
            //});
            //if (webCategoryId === 482) {
            //    this.EnhanceTranformation = enhanceTransformProducts;
            //}
        });
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }
    onSearchChangeEnhance(event) {
        this._sharedMethodService.headerSearchValue = event.target.value;
    }
    addToCart(item: any, quantity: any,type: any){
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item,
                    type:type,
                    
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                
            });
        }
        else{
            this._sharedMethodService.ContinueRetailOrder(item,quantity,type)
        }
    }

    getHeaderSearchedValue() {
        return this._sharedMethodService.headerSearchValue;
    }
}