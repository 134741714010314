<div id="Enrollment" class="page-layout">
    <div class="page-wrapper">
        <div class="ult-checkout-section wf-section">
      <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
          <summary-cart [routPath]="routPath" *ngIf="_enrollSession.mobileView"></summary-cart>
          <div class="checkout-div">
           <app-stepper  *ngIf="!_enrollSession.mobileView"></app-stepper>
            <h1 class="checkout-h1"></h1>
            <div fxLayout="row wrap" fxLayout.lt-lg="column" fxLayoutAlign="center flex-start" fxLayoutAlign.xs="center center">
                <div *ngFor="let items of itemsmemberkitBuilder | search: 'Description,ItemCode,ShortDetail,LongDetail': getHeaderSearchedValue()" fxFlex="48">
                    <div fxLayout="column" fxLayoutAlign="center center" class="m-b-30 m-r-10" >
                        <div class="itemTodayOrder" (click)="productInfo(items)">
                            <img src="{{items.SmallPicture}}" loading="lazy" alt="image" class="checkout-product-img">
                        </div>
                        <div>
                            <h2 class="checkout-h2">{{items.Description}}</h2>
                        </div>
                        <div>
                            <h3 class="checkout-h3" [innerHTML]="items.ShortDetail" *ngIf="false"></h3>
                        </div>
                        <div class="todays-order-price mh-22">LOYAL CUSTOMER PRICE : ${{items.Price |number : '1.2-2'}}</div>
                        <div class="todays-order-price mh-22 r-b">RETAIL PRICE : ${{items.Other1Price |number : '1.2-2'}}</div>
                        <div>
                            <a (click)="updateEnrollmentType(22);addToCart(items,1,1);" class="button w-button b-w hover-white">Add To Cart</a>
                        </div>
                        <ng-container *ngIf="items.ItemCode ==11200">
                            <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                                <a class="checkout-footer-link-blocl w-inline-block">
                                    <h3 class="checkout-h3 checkout-h3-footer cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[0],1,1)">Customize your ULT</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="items.ItemCode ==11210">
                            <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                                <a class="checkout-footer-link-blocl w-inline-block">
                                    <h3 class="checkout-h3 checkout-h3-footer cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[1],1,1)">Customize your ULT+Performance</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                                </a>
                            </div>
                        </ng-container>

                        <!-- For Fruit and Veggie site type -->
                        <ng-container *ngIf="items.ItemCode ==12137">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[0],1,1)">Customize your Fruit & Veggie+ Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="items.ItemCode ==12138">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[1],1,1)">Customize your Fruit & Veggie+ 3-Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>

                        <!-- For CBD site type -->
                        <ng-container *ngIf="items.ItemCode ==3750-4">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[0],1,1)">Customize your CBD+ (Peppermint) Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="items.ItemCode ==3751-4">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkit[1],1,1)">Customize your CBD+ (Unflavored) Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- <div *ngFor="let items of itemsmemberkit">
                    <div fxLayout="column" fxLayoutAlign="center center" class="m-b-30 m-r-10" *ngIf="siteType == 'fruitsandveggie'">
                        <div class="itemTodayOrder" (click)="productInfo(items)">
                            <img src="{{items.SmallPicture}}" loading="lazy" alt="image" class="checkout-product-img">
                        </div>
                        <div>
                            <h2 class="checkout-h2">{{items.Description}}</h2>
                        </div>
                        <div>
                            <h3 class="checkout-h3" [innerHTML]="items.ShortDetail" *ngIf="false"></h3>
                        </div>
                        <div class="todays-order-price mh-22">LOYAL CUSTOMER PRICE : ${{items.Price |number : '1.2-2'}}</div>
                        <div class="todays-order-price mh-22 r-b">RETAIL PRICE : ${{items.Other1Price |number : '1.2-2'}}</div>
                        <div>
                            <a (click)="updateEnrollmentType(22);_sharedMethodService.ContinueRetailOrder(items,1,1);" class="button w-button b-w">Add To Cart</a>
                        </div>
                        <ng-container *ngIf="items.ItemCode ==12137">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkitBuilder[0],1,1)">Customize your Fruit & Veggie+ Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="items.ItemCode ==12138">
                            <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                                <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="updateEnrollmentType(22);popupLoad(itemsmemberkitBuilder[1],1,1)">Customize your Fruit & Veggie+ 3-Pack</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="" class="image">
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
            </div>
            <h1 class="text-center order-summary-h1 all-products-accordion p-relative checkout-h2" *ngIf="(itemsmemberkitBuilder | search : 'Description,ItemCode,ShortDetail,LongDetail' : getHeaderSearchedValue())?.length == 0">{{siteType == cbd ? 'CBD+ Item Not Found': 'Cante pack Item Not Found'}}</h1>
          </div>
          <summary-cart [routPath]="routPath" *ngIf="!_enrollSession.mobileView"></summary-cart>
        </div>
      </div>
</div>