import { NgModule } from '@angular/core';
import {ShippingModule} from './Shipping/Shipping.module';
import { ConfirmationModule } from './Confirmation/Confirmation.module';
import { TodaysOrderModule } from './Todays-order/Todays-order.module';
import { EnhanceModule } from './Orders/Enhance.module';
import { SubscribeModule } from './Subscriptions/Subscribe.module';
import { CheckoutModule } from './Checkout/Checkout.module';
import { SummaryCartModule } from './summary-cart/summary-cart.module';
import { PipesModule } from './pipe/pipe.module';
import { StepperModule } from './stepper/stepper.module';
// import { SubscribeDialogComponent } from './subscribe-dialog/subscribe-dialog.component';
import { PasswordResetModule } from './password-reset/passwprd-reset.module';
import { ReviewPageModule } from './ReviewPage/ReviewPage.module';
@NgModule({
    imports: [
        TodaysOrderModule,
        EnhanceModule,
        SubscribeModule,
        ShippingModule,
        ConfirmationModule,
        CheckoutModule,
        SummaryCartModule,
        PipesModule,
        StepperModule,
        PasswordResetModule,
        ReviewPageModule

    ],
    exports: [
        TodaysOrderModule,
        EnhanceModule,
        SubscribeModule,
        ShippingModule,
        ConfirmationModule,
        CheckoutModule,
        PipesModule,
        PasswordResetModule,
        ReviewPageModule
    ],
    declarations: [
    // SubscribeDialogComponent
  ]
})
export class PagesModule
{

}
