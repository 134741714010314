
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StepperService } from 'app/shared/stepper.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { objectToParams } from 'app/constants';
import { AuthService } from 'app/shared/auth.service';
import { AutoOrderDialogComponent } from 'app/shared/auto-order-dialog/auto-order-dialog.component';
import * as moment from 'moment';
import * as _ from 'underscore';
import { UserDialogComponent } from 'app/shared/user-dialog/user-dialog.component';

@Component({
    selector: 'app-todays-order',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Todays-order.component.html',
    styleUrls: ['./Todays-order.component.scss']
})
export class TodaysOrderComponent implements OnInit, AfterViewInit {
    location: Location;
    // EnrollSession: any;
    ShowShippingMethod: boolean = false;
    stepperShow: boolean = false;
    webalias: any;
    itemsmemberkit: any;
    itemsmemberkitBuilder: any;
    BasicKitItem: any;
    LaunchKitItem: any;
    BuilderKitItem: any;
    customertype: any;
    ShippingMethods: any;
    siteType: any;
    url:any;
    params: any;
    routPath: any = "Enhance";
    SSOToken: any;
    loginDetails: any;
    customerDetails: any;
    SubscriptionProcessType: any;
    public appLoader: boolean = false;
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        private _location: Location,
        private _activateRoute: ActivatedRoute,
        public _sharedMethodService: SharedMethodService,
        public _stepperService: StepperService,
        public _enrollSession: EnrollSessionService,
        private _matDialog: MatDialog,
        private router: Router,
        public _auth: AuthService
    ) {
        this._enrollSession.data.customertype = 22;
        this.location = this._location;
        if(this._enrollSession.editTransformationPack!= 'true') {
            this._enrollSession.data = {};
        }
        // localStorage.clear();
        if (this._enrollSession.data && this._enrollSession.editTransformationPack!= 'true' && Object.keys(this._enrollSession.data).length === 0 ) {
            this._enrollSession.data.IsShowCart = false;
            this._enrollSession.data.page = this.location.path();
            this._enrollSession.data.TotalItemLength = 0;
            this._enrollSession.data.RetailEnroll = {};
            this._enrollSession.data.InitOrder = [];
            this._enrollSession.data.InitialOrdersSubTotal = 0;
            this._enrollSession.data.RetailOrderSummary = {};
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.EligibleForFreeUpgrade = false;
            this._enrollSession.data.RetailEnroll.IsNonprofit = false;
            this._enrollSession.data.FileUploaded = false;
            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
            this._enrollSession.data.usePrimaryCard = null;
            this._enrollSession.data.primaryCreditCardField = {};
            this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
            this._enrollSession.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this._enrollSession.data.IsUpGrade) {
                localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
            }
            this._enrollSession.data.Volume109 = 0;
            this._enrollSession.data.SmartOrderBVTotal = 0;
            this._enrollSession.data.InitialOrdersQVTotal = 0;
            this._enrollSession.data.AddOnOrdersQVTotal = 0;
        }
        this.url = window.location.href;
        this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard');
        this.siteType = this._activateRoute.snapshot.queryParamMap.get('site') || 'cbd';
        this.SSOToken = this._activateRoute.snapshot.queryParamMap.get('SSOToken');
        
        if(this.url == "https://fruitandveggie.puriumenrollment.com/TodaysOrder")
        {
            this.siteType = "fruitsandveggie";
            sessionStorage.setItem("siteType", this.siteType);
        }

        if (this.SSOToken) {
            this.SSOToken = this.SSOToken.replace(/\s/g, "+");
            sessionStorage.setItem("SSOToken", this.SSOToken);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.SSOToken;
            this.router.navigate([], { queryParams: params });
        } else {
            this.SSOToken = sessionStorage.getItem("authtoken");
        }

        if (this.siteType) {
            sessionStorage.setItem("siteType", this.siteType);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.products
            this.router.navigate([]);

        } else {
            this.siteType = sessionStorage.getItem("siteType");
            this.router.navigate([]);
        }
        window.scrollTo(0, 0);
    }

    GetCustomersDetails(customerId) {
        const self = this;
        let req = "?CustomerID=" + (customerId ? customerId : 0);
        self._restService.GetCustomersDetails(req).subscribe(
            (res: any) => {
                self._auth.sendUserData(JSON.stringify(res.customerDetail[0]));
                self._auth.getUserData();
                self._auth.sendDefaultCountryCode("1");
                self._toastr.success('Welcome ' + res.customerDetail[0].FirstName + res.customerDetail[0].LastName + '!', 'Success');
                this._enrollSession.data.LoggedInUserData = res.customerDetail[0];
            },
            error => {
                console.log(error, 'in error');
            }
        )
        self._restService.GetCustomerBilling(customerId).subscribe((res) => {
            self._auth.sendBillingInfo(JSON.stringify(res));
        }, error => {
            console.log("In error")
        })
    }
    getUserData(customerDetail) {
        if (this._auth.isLoggedIn()) {
          this.customertype = customerDetail.CustomerType || 22;
           // window.location.reload();
        }
      }
    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        if(this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
            const request = '?WebAlias=' + this.webalias;

            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if(result.Data != null) {
                    this._enrollSession.data.webAlias = this.webalias;
                }else{
                    this._toastr.error('Invalid WebAlias.', 'Error');
                }
            }, error => {
                this._toastr.error('Somthing is wrong Please try again', 'Error');
            });
        }

        if (this.SSOToken) {
            const request = objectToParams({
                client_id: this.SSOToken,
                grant_type: 'password',
                userName: 'SSO',
                scope: 'SSOToken'
            });
            this._restService.Token(request).subscribe({
                next: (result: any) => {
                    if (result && result.access_token) {
                        try {
                            this.loginDetails = result;
                            this._auth.sendToken(result);
                            this.GetCustomersDetails(this.loginDetails.CustomerID.toString());
                            this._toastr.success('User login successfully', 'Success');
                            this._enrollSession.data.IsLoggedIn = true;
                            if (sessionStorage.getItem('newCustomer') != 'true') {
                                this._restService.GetAutoOrders(result.CustomerID).subscribe((res: any) => {
                                    if (res.AutoOrderList.length > 0) {
                                      let CustomAutoOrderList = [];
                                      res.AutoOrderList.forEach(item => {
                                        if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                                          CustomAutoOrderList.push(item);
                                        }
                                      });
                                    
                                      var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                                      CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                                        return (item.AutoOrderID == minAutoOrderId);
                                      });
                                      
                                      sessionStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                                      sessionStorage.setItem("AutoOrderDetails", JSON.stringify( CustomAutoOrderList[0]));
                                      this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1": "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                                      localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                                      this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                                      this._enrollSession.data.RetailEnroll.ShippingMethod = CustomAutoOrderList[0].ShipMethodID;
                                      this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                                      this._enrollSession.data.RetailEnroll.ShippingMethod = CustomAutoOrderList[0].ShipMethodID;
                                      
                                      CustomAutoOrderList[0].Details.forEach(Customdetail => {
                                        if (!Customdetail.ParentItemCode) {
                                          const itemObj = {
                                            ItemCode: Customdetail.ItemCode,
                                            Description: Customdetail.Description,
                                            Quantity: Customdetail.Quantity,
                                            Price: Customdetail.PriceTotal,
                                            BusinessVolume: Customdetail.BusinesVolume,
                                            ParentId: Customdetail.ItemCode,
                                            SmallPicture: Customdetail.SmallPicture
                                          };
                                          this._enrollSession.data.smartOrderDate = moment(CustomAutoOrderList[0].NextRunDate).format('MM/DD/YYYY');
                                          this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                                          localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
                                        }
                                        else {
                                          const itemObj = {
                                            ItemCode: Customdetail.ItemCode,
                                            Description: Customdetail.Description,
                                            Quantity: Customdetail.Quantity,
                                            Price: Customdetail.PriceTotal,
                                            BusinessVolume: Customdetail.BusinesVolume,
                                            ParentId: Customdetail.ParentItemCode,
                                            SmallPicture: Customdetail.SmallPicture
                                          };
                                          this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                                          localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
                                        }
                                      });
                                    }
                                  }, error => {
                                    console.log("In error")
                                  })
                              }
                        } catch (successEx) {
                            this._toastr.error('Error', 'Oops Something Went Wrong');
                            console.error('ex', successEx);
                        }
                    } else {
                        this._toastr.error('Error', 'Authentication Failed');
                        this.appLoader = false;
                    }
                }, error: (err) => {
                    this._toastr.error(err.error.error_description);
                    this.appLoader = false;

                },
                complete: () => {
                }
            })
        }
        this.getMemberShipKit();
    }
    getMemberShipKit() {
        let request: any;
        if(this.siteType && this.siteType?.toLowerCase() == "fruitsandveggie"){
            request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 491,
                'isUpgradeCustomer': false
            }
        } 
        else if(this.siteType && this.siteType.toLowerCase() == "collagen") {
            request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 494,
                'isUpgradeCustomer': false
            }
        }
        else if(this.siteType && this.siteType.toLowerCase() == "cbd") {
            request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 512,
                'isUpgradeCustomer': false
            }
        }
        else {
            request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 481,
                'isUpgradeCustomer': false
            }
        }
        
        this._restService.getProducts(request).subscribe((result: any) => {
            if (result) {
                this.customerDetails =  JSON.parse(localStorage.getItem('LoggedInUserData'));
                var filterItems = [];
                var filterItemsBuild = [];
                result.Items.forEach((item) => {

                    if(this.siteType && this.siteType.toLowerCase() == "fruitsandveggie"){
                        if ((item.CategoryID === 491) && (item.ItemCode == "12139" || item.ItemCode == "12141")) {
                            filterItems.push(item);
                        }
                    }
                    else if(this.siteType && this.siteType.toLowerCase() == "collagen"){
                        if ((item.CategoryID === 494)) {
                            filterItems.push(item);
                        }
                    }
                    else if(this.siteType && this.siteType.toLowerCase() == "cbd"){
                        if ((item.CategoryID === 512) && (item.ItemCode == "3750-4" || item.ItemCode == "3751-4")) {
                            filterItems.push(item);
                        }
                    }
                    else if (item.CategoryID === 653 && item.ItemCode === "12140") {
                        filterItemsBuild.push(item)  
                    } 
                    
                    if (item.CategoryID === 491 && item.ItemCode === "12137") {
                        filterItemsBuild.push(item)  
                    }
                    if (item.CategoryID === 491 && item.ItemCode === "12138") {
                        filterItemsBuild.push(item)  
                    }
                    if (item.CategoryID === 494) {
                        filterItemsBuild.push(item)
                    }
                    if(this._auth.isLoggedIn())
                    {
                        if ((item.CategoryID === 512) && ((this.customerDetails.MainCountry=='US' && item.IsUSOnly==true) || (this.customerDetails.MainCountry=='CA' && item.IsCAOnly==true) || (item.IsCAOnly==false && item.IsUSOnly==false))) {
                            filterItemsBuild.push(item)
                        }
                        else{
                            this._toastr.error("Products are not available for Canada", "error");
                        }
                    }
                    else if ((item.CategoryID === 512)) {
                        filterItemsBuild.push(item)
                    }

                    //if(item.CategoryID === 420 && item.ItemCode === "11205") {
                    //    filterItems.push(item);
                    //}
                    //if(item.CategoryID === 442 && item.ItemCode === "11220") {
                    //    filterItems.push(item);
                    //}

                });
                if(filterItems && filterItems.length>0) {
                    this.itemsmemberkit = filterItems;
                }
                if(filterItemsBuild && filterItemsBuild.length>0){
                    this.itemsmemberkitBuilder = filterItemsBuild;
                }
            }
        },
            error => {
                console.log(error);
                this._toastr.error('Unable to fetch products..', 'Error');
            });
    }
    updateEnrollmentType(customertype): any {
        this._enrollSession.data.customertype = customertype;
        // this._enrollSession.data.EnrollmentType = enrolltype;
        sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
    }
    popupLoad(item, qty, type): any {
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                this._sharedMethodService.subOrderItem = false;
            });
        }
        else if(this.siteType=='cbd' && (!item.KitMembers || item.KitMembers.length <= 0))
        {
            this._sharedMethodService.ContinueRetailOrder(item,1,1);
        }
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }

    addToCart(item: any, quantity: any,type: any){
        if (this._auth.isLoggedIn() || sessionStorage.getItem('newCustomer') == 'true' || sessionStorage.getItem("siteType")!='cbd') {
            this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
        }
        else {
            const dialogRef = this._matDialog.open(UserDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '356px',
                panelClass: '',
                disableClose: true,
                data: {
                    header: false,
                    linkBuilder_popupLoad: false
                  }
            });
            this._sharedMethodService.isOpenDialog=true;
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    const customerDetail = JSON.parse(this._auth.getUserData()) || {}
                    this.getUserData(customerDetail);
                    this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
                    this._sharedMethodService.isOpenDialog=false;
                    if(sessionStorage.getItem('newCustomer') != 'true')
                    {
                    this._restService.GetAutoOrders(customerDetail.CustomerID).subscribe((res:any) => {
                        if (res.AutoOrderList.length > 0) {
                            let CustomAutoOrderList = [];
                            res.AutoOrderList.forEach(item => {
                                if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                                    CustomAutoOrderList.push(item);
                                }
                            });
                            var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                            CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                                return (item.AutoOrderID==minAutoOrderId);
                            });
                            sessionStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                             sessionStorage.setItem("AutoOrderDetails", JSON.stringify( CustomAutoOrderList[0]));
                             this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1": "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                             localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                             this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                             this._enrollSession.data.RetailEnroll.ShippingMethod = CustomAutoOrderList[0].ShipMethodID;
                             CustomAutoOrderList[0].Details.forEach(Customdetail => {
                                                                           
                                        if (!Customdetail.ParentItemCode) {
                                            const itemObj3 = {
                                                ItemCode: Customdetail.ItemCode,
                                                Description: Customdetail.Description,
                                                Quantity: Customdetail.Quantity,
                                                Price: Customdetail.PriceTotal,
                                                BusinessVolume: Customdetail.BusinesVolume,
                                                ParentId: Customdetail.ItemCode,
                                                SmallPicture: Customdetail.SmallPicture
                                            };
                                            this._enrollSession.data.smartOrderDate = moment(CustomAutoOrderList[0].NextRunDate).format('MM/DD/YYYY');
                                            this._sharedMethodService.ContinueRetailOrder(itemObj3, Customdetail.Quantity, 3);
                                        }
                                        else {
                                            // This is for Multiple sub items
                                            const itemObj = {
                                                ItemCode: Customdetail.ItemCode,
                                                Description: Customdetail.Description,
                                                Quantity: Customdetail.Quantity,
                                                Price:Customdetail.PriceTotal,
                                                BusinessVolume: Customdetail.BusinesVolume,
                                                ParentId: Customdetail.ParentItemCode,
                                                SmallPicture: Customdetail.SmallPicture
                                            };
                                            this._sharedMethodService.ContinueRetailOrder(itemObj, Customdetail.Quantity, 3);
                                        }
                            });
                        }
                      }, error => {
                        console.log("In error")
                      })
                    }

                }else {
                    this._sharedMethodService.isOpenDialog=false;
                }
            });
        }
    }

    getHeaderSearchedValue() {
        return this._sharedMethodService.headerSearchValue;
    }
}
