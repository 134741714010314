import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../rest.service';

@Component({
  selector: 'cookie',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieComponent implements OnInit {
  mobileView:any;
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<CookieComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService,
    public _restService: RestService) {
  }

  ngOnInit(): void {
    let screenWidth = window.innerWidth
      if (screenWidth < 550) {
        this.mobileView = true;
      }
      else {
        this.mobileView = false;
      }
  }

    closeCookie(): void {
        this.dialogRef.close();
    }
}
