import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { RgetRegexService } from '../../shared/getRegex.service';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { RestService } from '../../shared/rest.service';
import * as _ from 'lodash';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import * as moment from 'moment';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { CountrychangeComponent } from './modals/Countrychange/Countrychange.component';
import { startOfDay, startOfWeek, addWeeks, subWeeks, startOfMonth, endOfWeek, subMonths, addMonths, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, getMonth } from 'date-fns';
import { CalendarMonthViewDay, CalendarView, DateFormatterParams } from 'angular-calendar';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
type CalendarPeriod = 'month';

const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value && control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'This field is required' }
        };
    }
};
@Component({
    selector: 'Shipping',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Shipping.component.html',
    styleUrls: ['./Shipping.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShippingComponent implements OnInit {
    routPath: any = "Checkout";
    customerInfoForm: FormGroup;
    shippingForm: FormGroup;
    shippingMethodForm: FormGroup;
    countriesListAll: any[] = [];
    countriesList: any[] = [];
    USOnlyAutoOrderItems: any[] = [];
    USOnlyOrderItems: any[] = [];
    USOnlyAddonOrderItems: any[] = [];
    stateList: any[] = [];
    CalculateInitialOrderPrice: any = {};
    billAdd: boolean;
    invalidzip1: boolean = false;
    currentYear = new Date().getFullYear();
    viewDate: any = this._enrollSession.data.smartOrderDate ? this._enrollSession.data.smartOrderDate : addMonths(new Date(), 1);
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = true;
    datesDisabled: any;
    _selectedDay: any;
    AutoShipStartDate: any;
    prevBtnDisabled: boolean = false;
    LoggedInUserData: any;
    nextBtnDisabled: boolean = false;
    checked:boolean=true;
    minDate: Date = subMonths(new Date(), 0);
    maxDate: Date = addMonths(new Date(), 1);
    disableDaysOfMonth = [22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    subscribtionStatus: any;
    selectedStateValue = "";
    SubscriptionProcessType:any;
    customerDetails:any;
    authtoken:any;
    public isStateCalling = false;
    // mobileView: any;
    constructor(
        private _formBuilder: FormBuilder,
        public _RgetRegexService: RgetRegexService,
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        private _matDialog: MatDialog,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private router : Router,
        public _auth: AuthService,
        private _toastr: ToastrService
    ) {
        
        if((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)){
            if(this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
                this._toastr.warning("Please select Transformation Pack", 'Warning');
            }
            this.router.navigate(['/TodaysOrder']);
        }
        this._enrollSession.data.RetailEnroll.CurrencyCode = 'USD';
        this._enrollSession.data.RetailEnroll.AgreeTerms = true;
        this._enrollSession.data.RetailEnroll.CardType = this._enrollSession.data.RetailEnroll.CardType || 'VISA';
        this.CalculateInitialOrderPrice = {};
        this.customerInfoForm = this.createCustomerInfoForm();
        this.shippingForm = this.createShippingForm();
        this.shippingMethodForm = this.createShippingMethodForm();
        this.SubscriptionProcessType = JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
        localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
        this.authtoken = localStorage.getItem('authtoken');
        this.customerDetails = JSON.parse(localStorage.getItem('LoggedInUserData')) || '';
    }
    ngOnInit(): void {
        this._enrollSession.data.RetailOrderSummary.customerInfoData = this._enrollSession.data.RetailOrderSummary.customerInfoData || this.customerDetails || {};
        this.customerInfoForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.customerInfoData = selectedValue;
        });
        this._enrollSession.data.RetailOrderSummary.shippingFormData = this._enrollSession.data.RetailOrderSummary.shippingFormData || {};
        this.shippingForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.shippingFormData = selectedValue;
            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
        });
        this.shippingMethodForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailEnroll.ShippingMethod = selectedValue.shippingType;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
            this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        });
       

            if (this._enrollSession.data.RetailOrderSummary.customerInfoData && Object.keys(this._enrollSession.data.RetailOrderSummary.customerInfoData).length !== 0) {
                let customerInfo = this._enrollSession.data.RetailOrderSummary.customerInfoData;
                if(this._auth.isLoggedIn()){
                    this.customerInfoForm.patchValue({
                        FirstName: customerInfo.FirstName,
                        LastName: customerInfo.LastName,
                        Email: customerInfo.Email,
                        Phone: customerInfo.Phone.replace(/-/g,""),
                        smsOptIn: customerInfo.smsOptIn,
                        emailOptIn: customerInfo.emailOptIn,
                        keepUpdate: customerInfo.keepUpdate
                    }) 
                } else {
                    this.customerInfoForm.patchValue({
                        FirstName: customerInfo.FirstName,
                        LastName: customerInfo.LastName,
                        Email: customerInfo.Email,
                        ConfirmEmail: customerInfo.ConfirmEmail,
                        Phone: customerInfo.Phone,
                        Password: customerInfo.Password,
                        ConfirmPassword: customerInfo.ConfirmPassword,
                        smsOptIn: customerInfo.smsOptIn,
                        emailOptIn: customerInfo.emailOptIn,
                        keepUpdate: customerInfo.keepUpdate
                    })
                }
            } else {
                this.customerInfoForm.patchValue({
                    keepUpdate: true
                })
            }
            setTimeout(() => {
            if (!_.isEmpty(this._enrollSession.data.RetailOrderSummary.shippingFormData)) {
                let shippingInfo = this._enrollSession.data.RetailOrderSummary.shippingFormData;
                this.shippingForm.patchValue({
                    firstName: shippingInfo.firstName || this.customerDetails.FirstName,
                    lastName: shippingInfo.lastName || this.customerDetails.LastName,
                    address1: shippingInfo.address1 || this.customerDetails.MainAddress1,
                    address2: shippingInfo.address2 || this.customerDetails.MainAddress2,
                    CountryCode: shippingInfo.CountryCode || this.customerDetails.MainCountry,
                    ZipCode: shippingInfo.ZipCode || this.customerDetails.MainZip,
                    City: shippingInfo.City || this.customerDetails.MainCity,
                    State: shippingInfo.State ||  this.customerDetails.MainState || this.customerDetails.MailState
                })
            }
        }, 200);


        let shippingMethodInfo = _.isEmpty(this._enrollSession.data.RetailOrderSummary.shippingMethodFormData) ? this._enrollSession.data.ShippingMethods[0].ShipMethodID : this._enrollSession.data.RetailEnroll.ShippingMethod;
        let shipInfo = shippingMethodInfo.toString();
        this.shippingMethodForm.get('shippingType').setValue(shipInfo);
        this.getCountryList();

        if(this.eligibleForRetailDeliveryFees()) {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {
                price : 0.27,
                description : 'Retail Delivery Fees',
                quantity : 1,
                image : 'assets/images/image/retail-delivery-fees.png'
            };;
        } else {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {};
        }
        this.customerInfoForm.get("smsOptIn").setValue(true);
        this.customerInfoForm.get("emailOptIn").setValue(true);

    }
    createCustomerInfoForm(): FormGroup {
        return this._formBuilder.group({
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            ConfirmEmail: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            Phone: ['', [Validators.required,Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)]],
            Password: ['', [Validators.required]],
            ConfirmPassword: ['', [Validators.required]],
            smsOptIn: [''],
            emailOptIn:[''],
            keepUpdate: ['']
        },
            {
                validator: [this.MatchPassword('Password', 'ConfirmPassword'), this.MatchEmail('Email', 'ConfirmEmail')]
            }
            );
    }
    createShippingForm(): FormGroup {
        return this._formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            address1: ['', [Validators.required]],
            address2: [''],
            CountryCode: ['', Validators.required],
            ZipCode: ['', Validators.required],
            City: ['', Validators.required],
            State: ['', Validators.required]
        });
    }
    createShippingMethodForm(): FormGroup {
        return this._formBuilder.group({
            shippingType: [this._enrollSession.data.RetailEnroll.ShippingMethod, [Validators.required]],
        });    
    }
    MatchPassword(Password: any, ConfirmPassword: any): any {
        return (customerInfoForm: FormGroup) => {
            const PasswordControl = customerInfoForm.controls[Password];
            const ConfirmPasswordControl = customerInfoForm.controls[ConfirmPassword];
            if (!PasswordControl || !ConfirmPasswordControl) {
                return null;
            }
            if (PasswordControl.value && PasswordControl.value.length < 6) {

                return customerInfoForm.controls[Password].setErrors({ passwordLength: true });
            }

            if (!ConfirmPasswordControl.value) {
                return customerInfoForm.controls[ConfirmPassword].setErrors({ required: true });
            }

            if (PasswordControl.value && PasswordControl.value !== ConfirmPasswordControl.value) {
                return customerInfoForm.controls[ConfirmPassword].setErrors({ passwordMismatch: true });
            }
            else {
                return customerInfoForm.controls[ConfirmPassword].setErrors(null);
            }
        };
    }

    MatchEmail(Email: any, ConfirmEmail: any): any {
        return (customerInfoForm: FormGroup) => {
            const EmailControl = customerInfoForm.controls[Email];
            const ConfirmEmailControl = customerInfoForm.controls[ConfirmEmail];
            if (!EmailControl || !ConfirmEmailControl) {
                return null;
            }

            if (!ConfirmEmailControl.value) {
                return customerInfoForm.controls[ConfirmEmail].setErrors({ required: true });
            }

            if (EmailControl.value && EmailControl.value !== ConfirmEmailControl.value) {
                return customerInfoForm.controls[ConfirmEmail].setErrors({ EmailMismatch: true });
            }
            else {
                return customerInfoForm.controls[ConfirmEmail].setErrors(null);
            }
        };
    }

    getCountryList(): void {
        const getStatesRequest = { CountryCode: this.customerDetails.MainCountry || 'US' };
        this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode || 'US';
        this._restService.GetCountryRegions(getStatesRequest)
            .subscribe((result: any) => {
                try {
                    this.countriesListAll = result.countriesField;
                    this.countriesList = _.filter(result.countriesField, (item) => {
                        if(sessionStorage.getItem("siteType")=='cbd')
                        {
                         return (item.countryCodeField === 'US')
                        }
                        else
                        {
                         return (item.countryCodeField === 'US' || item.countryCodeField === 'CA');
                        }
                    });
                    this._enrollSession.data.countriesList = this.countriesList ? this.countriesList : '';
                    this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode || result.countriesField[0].countryCodeField;
                    this.countryChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode);
                } catch (ex) {
                    console.log('ex', ex);
                }
            }
            );
    }
    countryChange(countryCode): void {
        //if (['US'].indexOf(countryCode) === -1) {
            _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAddonOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            if (this.USOnlyOrderItems.length > 0 || this.USOnlyAutoOrderItems.length > 0 || this.USOnlyAddonOrderItems.length > 0) {
                this.popupLoad('countrychange', countryCode, '', 'countrychange');
            }
            else {
                this.getStates(countryCode, 1);
            }
        // }
        // else {
        //     this.getStates(countryCode, 1);
        // }
    }
    getStates(country, type): void {
        this.isStateCalling = true;
        if (sessionStorage.getItem('temp')) {
            this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = sessionStorage.getItem('temp');
         }
        const getStatesRequest = { CountryCode: country };
        this._restService.GetCountryRegions(getStatesRequest)
            .subscribe((result: any) => {
                try {
                    if (parseInt(type, 10) !== 2) {
                        this.stateList = result.regionsField;
                        if(sessionStorage.getItem("siteType")=='cbd'){
                        this.stateList = _.filter(result.regionsField, (item) => {
                             return ( item.regionCodeField!='NE' && item.regionCodeField!='KS')
                        })
                        }
                        this._enrollSession.data.stateList = this.stateList ? this.stateList : '';
                        this._enrollSession.data.stateListOnBilling = this._enrollSession.data.stateListOnBilling || result.regionsField;
                        if (this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode && this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode !== this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) {
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.State = result.regionsField[0].regionCodeField || '';
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = ['US','CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                            sessionStorage.setItem('temp',this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode);
                            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
                        }
                        else {
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.State = this.customerDetails.MainState ? this.customerDetails.MainState : (this._enrollSession.data.RetailOrderSummary.shippingFormData.State ? this._enrollSession.data.RetailOrderSummary.shippingFormData.State : result.regionsField[0].regionCodeField);
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState || result.regionsField[0].regionCodeField;
                            sessionStorage.setItem('temp',this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode)
                        }
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                        //this.removeMarketSpecificItems();
                        if (['US','CA'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.State) > -1) {
                            this.stateChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.State, 1);
                        }
                    }
                    else {
                        this._enrollSession.data.stateListOnBilling = result.regionsField;
                        if (this.billAdd) {

                            this._enrollSession.data.RetailOrderSummary.shippingFormData.BillState = ['US','CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                        }

                    }
                    this.isStateCalling = false;

                } catch (ex) {
                    console.log('ex', ex);

                }
            }
            );
    }
    
    stateChange(stateCode, type): void {
        if (['US','CA'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
            _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAddonOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            if (this.USOnlyOrderItems.length > 0 || this.USOnlyAutoOrderItems.length > 0 || this.USOnlyAddonOrderItems.length > 0) {
                this.popupLoad('countrychange', '', type, 'statechange');
            }
            else {
                if (parseInt(type, 10) !== 2) {
                    this.removeMarketSpecificItems();
                }
                this.checkCalculateOrder();
            }
        }
        else {
            if(!this.isStateCalling){
                this.checkCalculateOrder();
            }
        }
        
    }

    removeMarketSpecificItems(): void {
        if (this.USOnlyOrderItems.length > 0 || this.USOnlyAutoOrderItems.length > 0 || this.USOnlyAddonOrderItems.length > 0) {
            _.each(this.USOnlyOrderItems, (item, index) => {
                this.removeInitOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });
            _.each(this.USOnlyAutoOrderItems, (item, index) => {
                this.removeSmartOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });
            _.each(this.USOnlyAddonOrderItems, (item, index) => {
                this.removeAddonOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });

            if (this.USOnlyOrderItems.length > 0 || this.USOnlyAddonOrderItems.length > 0) {
                this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                    this.CalculateInitialOrderPrices(result);
                });
            }
            if (this.USOnlyAutoOrderItems.length > 0) {
                this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
            }
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            this.USOnlyAddonOrderItems = [];
        }
        else {
            this.calculateOrder();
        }
    }

    isAnyItemInCart = () => {
        return (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack.length > 0 || this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length > 0  || this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders.length > 0)
    }

    eligibleForRetailDeliveryFees = () => {
        return (this._enrollSession.data.RetailOrderSummary.shippingFormData.State == 'CO' && this.isAnyItemInCart() && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax > 0);
    }

    checkCalculateOrder(): void {
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = _.cloneDeep(this._enrollSession.data.RetailOrderSummary.shippingFormData.State);
        this._calculateRetailInitialOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
            this.CalculateInitialOrderPrices(result);
            this.checkShipMethod(this.shippingMethodForm.value.shippingType);
            this._enrollSession && this._enrollSession.data && this._enrollSession.data.ShippingMethods && this._enrollSession.data.ShippingMethods[0].ShipMethodID && this.shippingMethodForm.get('shippingType').setValue(this._enrollSession.data.ShippingMethods[0].ShipMethodID.toString());
        });
        this._calculateRetailSmartOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
           //this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        });
    }
    CalculateInitialOrderPrices(items): void {
        _.each(items.CustomDetails, (item) => {
            if (item.ParentItemCode && item.ParentItemCode !== null && item.ParentItemCode !== 'null') {
                this.CalculateInitialOrderPrice[item.ItemCode + '_' + item.ParentItemCode] = item.PriceEach;
            }
            else {
                this.CalculateInitialOrderPrice[item.ItemCode] = item.PriceEach;
            }

            //adding Retail Delivery Fees for Colorado State
            if(item.ItemCode == "CO_DeliveryFee" && this.eligibleForRetailDeliveryFees()) {
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {
                    price : item.PriceEach,
                    description : 'Retail Delivery Fees',
                    quantity : item.Quantity,
                    image : 'assets/images/image/retail-delivery-fees.png'
                };
            } else {
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {};
            }
        });
    }

    calculateOrder(): void {
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
        this._calculateRetailInitialOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
            this.shippingMethodForm.value.shippingType = this.shippingMethodForm.value.shippingType || this._enrollSession.data.ShippingMethods[0].ShipMethodID;
            this.CalculateInitialOrderPrices(result);
            this.checkShipMethod(this.shippingMethodForm.value.shippingType);
        });
        this._calculateRetailSmartOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
        });

    }
    checkShipMethod(shipMethodId): void {
        var isExist = _.filter(this._enrollSession.data.ShippingMethods, function (item) {
            return parseInt(item.ShipMethodID, 10) == parseInt(shipMethodId, 10);
        });
        if (isExist.length == 0) {
            this.shippingMethodForm.value.shippingType = this._enrollSession.data.ShippingMethods[0].ShipMethodID;
            //this.shippingMethodForm.get('shippingType').setValue(this._enrollSession.data.ShippingMethods[0].ShipMethodID);
        }


    }
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);

        });

        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {

            return item.ParentId !== Value.parentId;
        });

        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
        if (!Value.check) {
            this.CheckRegisterType();
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
    }

    removeAddonOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.AddOnOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.AddOnOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.AddOnOrdersSubTotal = 0;
        this._enrollSession.data.AddOnOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            this._enrollSession.data.AddOnOrdersSubTotal = this._enrollSession.data.AddOnOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.AddOnOrdersQVTotal = this._enrollSession.data.AddOnOrdersQVTotal + (item.Quantity * item.BV);

        });

        this._enrollSession.data.RetailOrderSummary.AddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {

            return item.ParentId !== Value.parentId;
        });

        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterAddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.AddOnOrdersSubItems = _.groupBy(filterAddOnOrders, 'ParentId');
        if (!Value.check) {
            this.CheckRegisterType();
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
    }

    removeSmartOrder(Value: any): void {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
        if (!Value.check) {
            this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        }
        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
        });
    }

    CheckRegisterType(): void {
        this._enrollSession.data.isBeautyWellness = false;
        this._enrollSession.data.RetailEnroll.ShippingMethod = '';
        if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber === 'NP') {
            this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = '';

        }
        if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 !== 'HealthProfessional') {
            if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ||
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' ||
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'BeautyWellnessProfessional') {
                this._enrollSession.data.FileUploaded = this._enrollSession.data.FileUploaded || false;
            }
            else {
                this._enrollSession.data.FileUploaded = false;
            }
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
        else if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'HealthProfessional' && this._enrollSession.data.InitialOrdersQVTotal < 500) {
            this._enrollSession.data.FileUploaded = false;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });

        }
        else {
            this._enrollSession.data.FileUploaded = false;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }

    }

    popupLoad(modaltype: any, countrycode: any = '', type: any = 0, action: string = ''): any {
        let componentName: any;
        if (modaltype === 'countrychange') {
            componentName = CountrychangeComponent;
        }
        const dialogRef = this._matDialog.open(componentName, {
            width: '630px',
            panelClass: []
        });
        dialogRef.afterClosed().subscribe(result => {
            if (modaltype === 'countrychange') {
                if (action === 'countrychange') {
                    this.ChangeCountryCloseAction(result, countrycode);
                }
                else if (action === 'statechange') {
                    this.ChangeCountryStateCloseAction(result, type);
                }
            }

        });
    }

    ChangeCountryCloseAction(modalaction, countrycode): any {
        if (modalaction === 'No') {
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            this._enrollSession.data.RetailEnroll.CountryCode = 'US';
            if (this._enrollSession.data.RetailEnroll.OldCountryCode !== 'US') {
                this.getStates(this._enrollSession.data.RetailEnroll.CountryCode, 1);
            }
        }
        else {
            this.getStates(countrycode, 1);
        }
    }

    ChangeCountryStateCloseAction(modalaction, type): any {
        if (modalaction === 'No') {
            if (type && parseInt(type, 10) === 1) {
                this._enrollSession.data.RetailEnroll.State = this.stateList[0].regionCodeField;
                this._enrollSession.data.RetailEnroll.OldState = this.stateList[0].regionCodeField;
            }
            else {
                this._enrollSession.data.RetailEnroll.State = _.cloneDeep(this._enrollSession.data.RetailEnroll.OldState);
            }
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            this.USOnlyAddonOrderItems = [];
            this.checkCalculateOrder();
        }
        else {
            this.removeMarketSpecificItems();
            this.checkCalculateOrder();
        }
    }
    regexValidateZip1(event): void {
        const shippingForm = this.shippingForm ? this.shippingForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }

    

    dateIsValid(date: Date): boolean {
       
        return parseInt(moment().add(1, "M").format('MM')) === date.getMonth()+1  && date.getTime() > new Date().getTime();
        // return moment().month()+1 == date.getMonth() && date.getTime() > new Date().getTime()  && (this.disableDaysOfMonth.indexOf(date.getDate()) == -1) ;
    }

    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(day => {
            if (!this.dateIsValid(day.date)) {
                // console.log("date",day.date);        
                day.cssClass = 'disabled-date';
            }
            const _selectedDay = moment(day.date).format('MM/DD/YYYY') ===  this._enrollSession.data.smartOrderDate ? day : "";
            if (_selectedDay) {
                _selectedDay.cssClass = 'cal-selected';
            }
        });
    }

    getFirstAndThirdWed(year, month) {
        let currmonth = (parseInt(month, 10) + 1);
        let arrdays = [];
        let dateinmonth = moment().daysInMonth();
        while (dateinmonth) {
            var current = moment().date(dateinmonth);
            arrdays.push(current);
            dateinmonth--;
        }

        var enabledDatesArr = [];
        for (var y = year; y <= 2030; y++) {
            for (var j = currmonth; j <= 12; j++) {
                //enabledDatesArr.push(moment(j + '/1/' + y).format('MM-DD-YYYY'));
                for (var i = 22; i <= 31; i++) {
                    var dat = j + '/' + i + '/' + y;
                    enabledDatesArr.push(moment(dat).format('MM-DD-YYYY'));
                }
            }
        }
        arrdays.forEach(element => {
            if (moment(element._d).isSameOrBefore(moment())) {
                enabledDatesArr.push(moment(element._d).format('MM-DD-YYYY'));
            }
        });
        return enabledDatesArr;
    }

    changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }
    monthIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }
    dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.monthIsValid(
            this.endOfPeriod(this.viewDate, this.subPeriod('month', this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.monthIsValid(
            this.startOfPeriod(this.viewDate, this.addPeriod('month', this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }
    increment() {
        this.changeDate(this.addPeriod('month', this.viewDate, 1));
    }

    decrement() {
        this.changeDate(this.subPeriod('month', this.viewDate, 1));
    }



    dayClicked({ date, }: { date: Date; }): void {
        var maxMonth = getMonth(this.maxDate);
        if ((getMonth(date) && getMonth(date) <= maxMonth)  || (getMonth(date) == 0 && getMonth(date) <= maxMonth)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = moment(date).format('MM/DD/YYYY');
            this._enrollSession.data.smartOrderDate = this.viewDate;
            this.AutoShipStartDate = this.viewDate;
            localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
            //this.refresh.next();
        }
    }


    startOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: startOfDay,
            month: startOfMonth,
        }['month'](date);
    }


    endOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: endOfDay,
            week: endOfWeek,
            month: endOfMonth,
        }['month'](date);
    }

    addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: addDays,
            week: addWeeks,
            month: addMonths,
        }['month'](date, amount);
    }

    subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: subDays,
            week: subWeeks,
            month: subMonths,
        }['month'](date, amount);
    }
    EmailRegistered(emailData): void {
        if (!this.customerInfoForm.controls.Email['errors'] && !this.customerInfoForm.controls.ConfirmEmail['errors'] && this._enrollSession.data.RetailOrderSummary.customerInfoData.Email) {
            const mailrequest = '?email=' + this._enrollSession.data.RetailOrderSummary.customerInfoData.Email;
            this._restService.IsEmailRegistered(mailrequest).subscribe((result: any) => {
               if(!result) {
                this._toastr.error('Email Already Registered', 'Error');
                this.customerInfoForm.get("Email").reset();
               }
            })
        }
    }
    processTypeChange(value)
    {
        console.log("value", value);
        localStorage.setItem("SubscriptionProcessType", JSON.stringify(value));
    }
    // preventSpaceEnter(event) {
    //     var charCode = (event.which) ? event.which : event.keyCode;
    //     if ((charCode == "")) {
    //         return true;
    //     } else {
    //         event.preventDefault();
    //         return false;
    //     }
    // }
}
