import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { EnhanceComponent } from './Enhance.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';
const routes: Routes = [
    {
        path: 'Enhance',
        component: EnhanceComponent
    }
];

@NgModule({
    declarations: [
        EnhanceComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        MatExpansionModule,
        SummaryCartModule,
        MatTooltipModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule
    ],
    exports: [
        RouterModule,
        EnhanceComponent,

    ],
    entryComponents: [

    ],
    providers: [
    ]
})
export class EnhanceModule {
}

