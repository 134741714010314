import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { objectToParams } from 'app/constants';
import { LoaderService } from 'app/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { EnrollSessionService } from '../enrollSession.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { RestService } from '../rest.service';
import { SharedMethodService } from '../sharedMethod.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  customerLoginForm: FormGroup;
  loading: boolean;
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  public appLoader: boolean = false;
  public validateShow: boolean = false;
  public access_token: any;
  public linkBuilderLoginDialog: boolean = false;
  constructor(private _restService: RestService,
    public _matDialog: MatDialog, private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public _enrollSession: EnrollSessionService,
    private loaderService: LoaderService,
    public router: Router,
    private cookieService: CookieService,
    public _sharedMethodService: SharedMethodService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _shareMethodServices: SharedMethodService, private _auth: AuthService, private _toastr: ToastrService) {
    this.customerLoginForm = this.createCustomerLoginForm();
    this.access_token = sessionStorage.getItem('authtoken');
    if(this.data.linkBuilder_popupLoad){
      this.linkBuilderLoginDialog = false
    }
  }

  ngOnInit(): void {
    
      if(this.data?.header){
        this.returingCustomer();
      }else {
        return
      }

  }

  createCustomerLoginForm(): FormGroup {
    return this._formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  GetCustomersDetails(customerId) {
    const self = this;
    let req = "?CustomerID=" + (customerId ? customerId : 0);
    self._restService.GetCustomersDetails(req).subscribe(
      (res: any) => {
        this._enrollSession.data.priceType = res.customerDetail[0].PriceType;
        self._auth.sendUserData(JSON.stringify(res.customerDetail[0]));
        self._auth.getUserData();
        self._auth.sendDefaultCountryCode("1");
        self._toastr.success('Welcome ' + res.customerDetail[0].FirstName + res.customerDetail[0].LastName + '!', 'Success');
        this._enrollSession.data.LoggedInUserData = res.customerDetail[0];
        this.dialogRef.close(true);
      },
      error => {
        console.log(error, 'in error');
      }
    )
    self._restService.GetCustomerBilling(customerId).subscribe((res) => {
      self._auth.sendBillingInfo(JSON.stringify(res));
    }, error => {
      console.log("In error")
    })
  }
  returingCustomer(){
    this.userLoginDialog = true;
  }

  newCustomer(){
    sessionStorage.setItem('newCustomer', 'true');
    this.dialogRef.close(true);
  }
  SignIn() {
    if (this.access_token? this.customerLoginForm.valid: this.customerLoginForm.invalid) {
      this.validateAllFormFields(this.customerLoginForm);
      return;
    }
    if (localStorage.getItem('isLoggedIn') == 'true') {
      this._toastr.error('Error', 'User has already login');
      return;
    }
    this.appLoader = true;
    const request = objectToParams({
      client_id: 'exigo',
      grant_type: 'password',
      userName: this.userInfo.userName,
      password: this.userInfo.Password,
      scope: 'password'
    });
    this._restService.Token(request).subscribe({
      next: (result: any) => {
        if (result && result.access_token) {
          try {
            this.loginDetails = result;
            this._auth.sendToken(result);
            this.GetCustomersDetails(this.loginDetails.CustomerID.toString());
            sessionStorage.setItem('SSOToken', result.SSOToken); 
            this._toastr.success('User login successfully', 'Success');
            this._enrollSession.data.IsLoggedIn = true;
            setTimeout(() => {
              this.cookieService.delete('access_token', '/');
              this.cookieService.delete('X-Auth', '/');
              this.cookieService.deleteAll('/');
              this._enrollSession.data.RetailOrderSummary = {};
              this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
              this._enrollSession.data.IsLoggedIn = false;
              this._enrollSession.data.LoggedInUserData = {};
              this._enrollSession.data.priceType = 0;
              sessionStorage.setItem('onUltLifestyleFlow', 'false');
              this.router.navigate(['/Home']).then(() => {
                if (window.localStorage && window.sessionStorage) {
                  window.localStorage.removeItem('user');
                  window.localStorage.clear();
                  window.sessionStorage.clear();
                }
                window.location.reload();
              });
              sessionStorage.setItem("isExistingAutoship", "false");
              alert("Your session has been expired!!")
            }, 1800000);
          } catch (successEx) {
            this._toastr.error('Error', 'Oops Something Went Wrong');
            console.error('ex', successEx);
          }
        } else {
          this._toastr.error('Error', 'Authentication Failed');
          this.appLoader = false;
        }
      }, error: (err) => {
        this._toastr.error(err.error.error_description);
        let req = "?email=" + (this.access_token? 'SSO' : this.userInfo.userName);
        this.appLoader = false;
        this._restService.validateCustomer(req).subscribe((resy:any)=>{
          if(resy){
            let response = resy;
            if(response.ResetPasswordRequired){
              this.validateShow = true;
            }else{
              this.validateShow = false;
            }
          }
        })
      },
      complete: () => {
      }
    })
  }
  resetPassword() {
    const dialogRef = this._matDialog.open(ResetPasswordComponent, {
      width: '500px',
      maxWidth: '500px',
      height: '356px',
      disableClose: false,
    })
    this._shareMethodServices.isOpenDialog = true;
    this.dialogRef.close(false);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._shareMethodServices.isOpenDialog = false;
      }
    })
  }
  closeDialog() {
    this.dialogRef.close();
    this._sharedMethodService.isOpenDialog=false;
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

