import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { EnrollSessionService } from '../enrollSession.service';
import { RestService } from '../rest.service';
import { SharedMethodService } from '../sharedMethod.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  currentRouterPath: string;
  customerPasswordResetForm: FormGroup;
  public userInfo: any = {};
  public appLoader: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    private _formBuilder: FormBuilder,
    public _toastr: ToastrService, public _restService: RestService, public _sharedMethodService: SharedMethodService,) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.customerPasswordResetForm = this.createCustomerPasswordResetForm();
  }

  ngOnInit(): void {

  }

  createCustomerPasswordResetForm(): FormGroup {
    return this._formBuilder.group({
      userName: ['', [Validators.required]],
    })
  }
  
  SendLink() {
    if (this.userInfo.userName && (this.userInfo.userName != '')) {
      let request = '?email=' + (this.userInfo.userName);
      this.appLoader = true;
      this._restService.GetResetPasswordLink(request).subscribe(
        res => {
          this._toastr.success('Link Sent Successfully', 'Success');
          this._sharedMethodService.isOpenDialog=false;
          this.appLoader = false;
          this.dialogRef.close(true)
        }, error => {
          this._sharedMethodService.isOpenDialog=false;
          this.appLoader = false;
          this._toastr.error('Error', 'Oops Something Went Wrong');
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
    this._sharedMethodService.isOpenDialog=false;
  }
}
