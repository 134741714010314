import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'app/shared/rest.service';
import { Router } from '@angular/router';
import { API_URL } from 'app/constants';
import { RgetRegexService } from 'app/shared/getRegex.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from 'app/shared/auth.service';
declare var ka: any;
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    referredForm: FormGroup;
    submissionInProgress: boolean;
    billingAddressForm: FormGroup;
    paymentTypeForm: FormGroup;
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth() + 1;
    monthList: any[] = [];
    yearList: any[] = [];
    invalidzip1: boolean = false;
    sameAddressStatus: boolean = true;
    SmartOrderDate: any;
    SubscriptionProcessType: any;
    LoggedInUser: any;
    PaymentMethodDifferent: boolean = false;
    expirationYearField:any;
    expirationMonthField:any;
    creditCardNumberDisplayField:any
    paymentDetails : any;
    customerDetail: any;
    tokenizer: any = {};
    hasCardNumberCheck:boolean;
    hasSecurityCodeForAmerican:boolean;
    hasSecurityCodeForVDM:boolean;
    tokenError: boolean = false;
    private _unsubscribeAll: Subject<any>
    constructor(
        public _enrollSession: EnrollSessionService,
        private _toastr: ToastrService,
        private _formBuilder: FormBuilder,
        private _restService: RestService,
        private _route: Router,
        public _RgetRegexService: RgetRegexService,
        private router: Router,
        public _auth: AuthService

    ) {
        this._unsubscribeAll = new Subject();
        this.submissionInProgress = false;
        this.SmartOrderDate = this._enrollSession.data.smartOrderDate;
        this.LoggedInUser = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {}
        console.log(' this.paymentDetails', this.paymentDetails);
        
        this.referredForm = this.createReferredForm();
        this.paymentTypeForm = this.createPaymentTypeForm();
        this.billingAddressForm = this.createBillingAddressForm();
        this.expirationYearField = this.paymentDetails?.primaryCreditCardField?.expirationYearField || this.paymentDetails?.secondaryCreditCardField?.expirationYearField;
        this.expirationMonthField = this.paymentDetails?.primaryCreditCardField?.expirationMonthField || this.paymentDetails?.secondaryCreditCardField?.expirationMonthField;
        this.creditCardNumberDisplayField = this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField || this.paymentDetails?.secondaryCreditCardField?.creditCardNumberDisplayField;
        for (let i = 1; i <= 12; i++) {
            this.monthList.push({ key: i, value: i });
        }
        for (let i = 0; i < 20; i++) {
            this.yearList.push({ key: (this.currentYear + i), value: (this.currentYear + i) });
        }

        if ((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) || (this._enrollSession.data.RetailOrderSummary.customerInfoData == undefined)) {
            if (this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0 || this._enrollSession.data.RetailOrderSummary == undefined) {
                this._toastr.warning("Please select Transformation Pack", 'Warning');
                this.router.navigate(['/TodaysOrder']);
                return
            }
        }
        if ((this._enrollSession.data.RetailOrderSummary) && (this._enrollSession.data.RetailOrderSummary.customerInfoData == undefined && _.isEmpty(this._enrollSession.data.RetailOrderSummary.customerInfoData) || this._enrollSession.data.RetailOrderSummary.customerInfoData.Email == undefined || this._enrollSession.data.RetailOrderSummary.customerInfoData.Password == undefined || this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.firstName == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.lastName == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.City == undefined)) {
            console.log("call 1");
            this._toastr.warning("Please fill all required field", 'Warning');
            this.router.navigate(['/Shipping']);
            return
        }
        if (this._enrollSession.data.RetailOrderSummary?.SmartOrders?.length > 0) {
            this.SubscriptionProcessType = (JSON.parse(localStorage.getItem('SubscriptionProcessType')) == 'AutoOrderProcessTy2') ? 1 : 0;
        }
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};

    }
    createReferredForm(): FormGroup {
        return this._formBuilder.group({
            referralCode: ['', Validators.required]
        })
    }
    createPaymentTypeForm(): FormGroup {
        return this._formBuilder.group({
            primaryPaymentCard: [],
            cardNumber: [''],
            nameOnCard: ['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]],
            expMonth: ['', [Validators.required]],
            expYear: ['', [Validators.required]],
            securityCode: ['', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]]
        })
    }

    createBillingAddressForm(): FormGroup {
        return this._formBuilder.group({
            sameAddress: [],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            address1: ['', [Validators.required]],
            address2: [''],
            CountryCode: ['', Validators.required],
            ZipCode: ['', Validators.required],
            City: ['', Validators.required],
            State: ['', Validators.required]
        });
    }
    ngOnInit(): void {
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            for (let i = 0; i < this._enrollSession.data.ShippingMethods.length; i++) {
                let dataShipping = this._enrollSession.data.ShippingMethods[i];
                if (dataShipping.ShipMethodID == this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this._enrollSession.data.RetailEnroll.ShippingDescription = dataShipping.Description;
                }
            }
        }
        this._enrollSession.data.RetailOrderSummary.billingAddressData = this._enrollSession.data.RetailOrderSummary.billingAddressData || {};
        this.billingAddressForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.billingAddressData = selectedValue;
            this._enrollSession.data.RetailOrderSummary.billingAddressData.State = selectedValue.State
        });
        this._enrollSession.data.RetailOrderSummary.paymentTypeData = this._enrollSession.data.RetailOrderSummary.paymentTypeData || {};
        this.paymentTypeForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.paymentTypeData = selectedValue;
            if(selectedValue.primaryPaymentCard === 'true')
            {
                this.createIframe();
            }
        });
        this._enrollSession.data.RetailOrderSummary.referredFormData = this._enrollSession.data.RetailOrderSummary.referredFormData || {};
        this.referredForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.referredFormData = selectedValue;
        });
        this.billingAddressForm.get('sameAddress').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                if (!value) {
                    this._enrollSession.addValidators(this.billingAddressForm, "firstName");
                    this._enrollSession.addValidators(this.billingAddressForm, "lastName");
                    this._enrollSession.addValidators(this.billingAddressForm, "address1");
                    this._enrollSession.addValidators(this.billingAddressForm, "CountryCode");
                    this._enrollSession.addValidators(this.billingAddressForm, "ZipCode");
                    this._enrollSession.addValidators(this.billingAddressForm, "City");
                    this._enrollSession.addValidators(this.billingAddressForm, "State");
                }
                else {
                    this._enrollSession.removeValidators(this.billingAddressForm, "firstName");
                    this._enrollSession.removeValidators(this.billingAddressForm, "lastName");
                    this._enrollSession.removeValidators(this.billingAddressForm, "address1");
                    this._enrollSession.removeValidators(this.billingAddressForm, "CountryCode");
                    this._enrollSession.removeValidators(this.billingAddressForm, "ZipCode");
                    this._enrollSession.removeValidators(this.billingAddressForm, "City");
                    this._enrollSession.removeValidators(this.billingAddressForm, "State");
                }
            });
       this.paymentTypeForm.get('primaryPaymentCard').valueChanges.subscribe(selectValue => {
            if(selectValue == "true"){
                let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary.paymentTypeData;
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: paymentTypeInfo.CreditDebitCard,
                    cardNumber: this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber,
                    nameOnCard: paymentTypeInfo.nameOnCard,
                    expMonth: paymentTypeInfo.expMonth,
                    expYear: paymentTypeInfo.expYear,
                    securityCode: ''
                }); 
            }
            else if(selectValue == "false"){
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: '',
                    cardNumber: '',
                    nameOnCard: '',
                    expMonth: '',
                    expYear: '',
                    securityCode: '',
                });
            }
        });
        if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "false") {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("false");
        } else if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "true") {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("true");
        } else {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("true");
        }
        this.billingAddressForm.get('sameAddress').valueChanges.subscribe(selectedValue => {
            if (selectedValue == "true") {
                let billingAddressInfo = this._enrollSession.data.RetailOrderSummary.shippingFormData;
                this.billingAddressForm.patchValue({
                    firstName: billingAddressInfo.firstName,
                    lastName: billingAddressInfo.lastName,
                    address1: billingAddressInfo.address1,
                    address2: billingAddressInfo.address2,
                    CountryCode: billingAddressInfo.CountryCode,
                    ZipCode: billingAddressInfo.ZipCode,
                    City: billingAddressInfo.City,
                    State: billingAddressInfo.State
                });
            } else if (selectedValue == "false") {
                this.getStates('US');
                this.billingAddressForm.patchValue({
                    firstName: '',
                    lastName: '',
                    address1: '',
                    address2: '',
                    CountryCode: this._enrollSession.data.countriesList ? this._enrollSession.data.countriesList[0].countryCodeField : '',
                    ZipCode: '',
                    City: '',
                    State: this._enrollSession.data.RetailOrderSummary.billingAddressData.State ? this._enrollSession.data.RetailOrderSummary.billingAddressData.State : ''
                });
            }
        });
        if (this._enrollSession.data.RetailOrderSummary.billingAddressData.sameAddress == "false") {
            this.billingAddressForm.get("sameAddress").setValue("false");
        } else if (this._enrollSession.data.RetailOrderSummary.billingAddressData.sameAddress == "true") {
            this.billingAddressForm.get("sameAddress").setValue("true");
        } else {
            this.billingAddressForm.get("sameAddress").setValue("true");
        }

        if(!this._auth.isLoggedIn()){
            if (_.isEmpty(this._enrollSession.data.RetailOrderSummary.paymentTypeData)) {
                this.paymentTypeForm.patchValue({
                    expMonth: this.currentMonth,
                    expYear: this.currentYear,
                });
            }
            if (this._enrollSession.data.RetailOrderSummary.paymentTypeData) {
                let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary.paymentTypeData;
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: paymentTypeInfo.CreditDebitCard,
                    cardNumber: this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber,
                    nameOnCard: paymentTypeInfo.nameOnCard,
                    expMonth: paymentTypeInfo.expMonth,
                    expYear: paymentTypeInfo.expYear,
                    securityCode: paymentTypeInfo.securityCode,
                });
            }
        }
       
        if (this._enrollSession.data.RetailOrderSummary.billingAddressData) {
            let billingAddressInfo = this._enrollSession.data.RetailOrderSummary.billingAddressData;
            this.billingAddressForm.patchValue({
                firstName: billingAddressInfo.firstName,
                lastName: billingAddressInfo.lastName,
                address1: billingAddressInfo.address1,
                address2: billingAddressInfo.address2,
                CountryCode: billingAddressInfo.CountryCode,
                ZipCode: billingAddressInfo.ZipCode,
                City: billingAddressInfo.City,
                State: billingAddressInfo.State
            });
        }

        if (this._enrollSession.data.RetailOrderSummary.referredFormData) {
            let referredInfo = this._enrollSession.data.RetailOrderSummary.referredFormData;
            this.referredForm.patchValue({
                referralCode: referredInfo.referralCode ? referredInfo.referralCode : this._enrollSession.data.webAlias
            });
            this.getCustomerSite(this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode, 1);
        }

        this.customerDetail = JSON.parse(this._auth.getUserData()) || {};
    }


    ngAfterViewInit(): void {
        this.loadKount();
        setTimeout(() => {
            //@ts-ignore
            this.tokenizer = new TokenExIframeClient("cc-wrapper", {
              styles: {
                base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                focus: "box-shadow:none;border: none;outline: 0;",
                error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
              }
            });
      
          }, 5000);
    }
    differentPaymentMethod(type) {
        if(type=='paymentDifferent'){
            this.PaymentMethodDifferent = true;
            this.createIframe();
        }
        else {
            this.PaymentMethodDifferent = false;
        }
       
    }

    loadKount() {
        var fraudId = "";
        var client = new ka.ClientSDK();
        client.setupCallback(
          {
            'collect-end':
              function (params) {
                console.log("Collect started");
                console.log("Session ID: " + params['MercSessId']);
                fraudId = params['MercSessId'];
                $("#FraudGuid").val(fraudId);
              },
            'collect-begin':
              function (params) {
                console.log("Collect finished");
                console.log("Collect started");
                console.log("Session ID: " + params['MercSessId']);
                fraudId = params['MercSessId'];
                $("#FraudGuid").val(fraudId);
              }
          });
        client.autoLoadEvents();
      };

    getStates(country): void {
        if (country) {
            const getStatesRequest = { CountryCode: country };
            this._restService.GetCountryRegions(getStatesRequest)
                .subscribe((result: any) => {
                    this._enrollSession.data.stateList = result.regionsField;
                    this._enrollSession.data.RetailOrderSummary.billingAddressData.State = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                }
                );
        }

    }

  

    createIframe() {
        setTimeout(() => {
            this.loadKount();
            //@ts-ignore
            this.tokenizer = new TokenExIframeClient("cc-wrapper", {
              styles: {
                base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                focus: "box-shadow:none;border: none;outline: 0;",
                error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
              }
            });

            this.tokenizer.change((res) =>{
                this.FocusOutTokenEx(true);
            });

            console.log('tokenizer',this.tokenizer);
            
            setTimeout(() => {
              $('#cardiframe').click(function (e) {
                $('#tx_iframe_cc-wrapper')[0]?.focus();
              });
            }, 100)
          }, 1000);
    }

    fraudPrevention() {
        let tokenCheck:boolean = true;
        this.tokenizer.tokenize({
            success: (data) => {
              console.log('tokenized success', data);
              var token = data.token;
              $(".creditcard-token").val(token);
              $(".creditcard-display").val(token.substr(token.length - 4, 4));
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = token;
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize = data;
              this._enrollSession.data.RetailEnroll.CardType = data.cardType;
              if(tokenCheck){
                  this.getSubmit();
              }
              tokenCheck = false;
            },
            error: (data) => {
              console.log("get cc token failed", data);
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber= "";
              this._enrollSession.data.RetailEnroll.CardType = "";
              if(tokenCheck){
                  this._toastr.error(data?.message,'error');
              }
              tokenCheck = false;
              return;
            }
          });
    }
    getSubmit(): void {
        console.log("call submit");
        if ((this._auth.isLoggedIn() ? this.referredForm.valid : this.referredForm.invalid) || this.billingAddressForm.invalid || (this._auth.isLoggedIn()?(this.paymentTypeForm.get('primaryPaymentCard').value === 'true'? this.paymentTypeForm.valid: this.paymentTypeForm.invalid):this.paymentTypeForm.invalid)) {
            return;
        }
        this.validateCardNumber(this._enrollSession.data.RetailOrderSummary.paymentTypeData?.tokenize?.firstSix);
        if(!this.hasCardNumberCheck){
            this._toastr.error('Please check your Card Number.','error');
            return;
        }
        let smartOrderDateDefault = moment().add(1, 'months').format('MM/DD/YYYY');
        this.SmartOrderDate = this._enrollSession.data?.smartOrderDate ?  this._enrollSession.data?.smartOrderDate : this._enrollSession.data?.smartOrderDate || smartOrderDateDefault;
        this.submissionInProgress = true;
        let request: any;
        request =
        {
            'CustomerId': this.LoggedInUser && Object.keys(this.LoggedInUser).length > 0 ? this.LoggedInUser.CustomerID : this._enrollSession.data.customertype === 22 ? 0
                : (this._enrollSession.data.IsUpGrade ? this._enrollSession.data.IsUpGrade : this._enrollSession.data.CustomerId ?
                    this._enrollSession.data.CustomerId : 0),
            'AcceptTerms': this._enrollSession.data.RetailEnroll.AgreeTerms ? this._enrollSession.data.RetailEnroll.AgreeTerms : true,
            'Field5': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? 'Influencer' :
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ?
                    'NonProfit' : this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' ?
                        'Influencer' : this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'BeautyWellnessProfessional' ? 'Beauty/Wellness Professional' : '',
            'IsProfessional': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? false :
                (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'HealthProfessional' &&
                    this._enrollSession.data.FileUploaded ? true : false),
            'IsNonprofit': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? false :
                (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ||
                    this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' && this._enrollSession.data.FileUploaded ? true : false),
            'ApplicantAddress': {
                'Address1': this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 : '',
                'Address2': this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 : '',
                'Address3': '',
                'City': this._enrollSession.data.RetailOrderSummary.shippingFormData.City ? this._enrollSession.data.RetailOrderSummary.shippingFormData.City : '',
                'CountryCode': this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode ? this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode : '',
                'State': this._enrollSession.data.RetailOrderSummary.shippingFormData.State ? this._enrollSession.data.RetailOrderSummary.shippingFormData.State : '',
                'Zip': this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode ? this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode : ''
            },
            //'BirthDate': '',
            'Company': '',
            // 'CustomerType': this._enrollSession.data.customertype === 22 ? this._enrollSession.data.customertype : 22,
            'CustomerType': this.customerDetail.CustomerType? this.customerDetail.CustomerType: 22,
            'CustomerWebAlias': this._enrollSession.data.ReferredByGiftCard ? this._enrollSession.data.ReferredByGiftCard : 0,
            'Email': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
            'EnrollerWebAlias': this._enrollSession.data.ReferredBy ? this._enrollSession.data.ReferredBy : '',
            'FirstName': this._enrollSession.data.RetailOrderSummary.customerInfoData.FirstName || '',
            'LastName': this._enrollSession.data.RetailOrderSummary.customerInfoData.LastName || '',
            'Gender': '',
            'BusinessName': this._enrollSession.data.RetailEnroll.BusinessName ? this._enrollSession.data.RetailEnroll.BusinessName : '',
            'TaxId': this._enrollSession.data.RetailEnroll.EINNumber ? this._enrollSession.data.RetailEnroll.EINNumber :
                this._enrollSession.data.RetailEnroll.CommissionSSN,
            'TaxIDType': 'SSN',
            'LanguageCode': 'en',
            'OrderRequest': {
                'CurrencyCode': 'USD',
                'Details': this._enrollSession.data.OrderProductDetalis ? this._enrollSession.data.OrderProductDetalis : '',
                'ShipMethodID': this._enrollSession.data.RetailEnroll.ShippingMethod || this._enrollSession.data.Order_shipMethodIDField,
                'IsShipAmntDiscount': this._enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal >= 250 || ''
            },
            'PartyID': '',
            'Password': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? '' : this._enrollSession.data.RetailOrderSummary.customerInfoData.Password,
            'PriceType' : this.customerDetail.PriceType ?this.customerDetail.PriceType:  this._enrollSession.data.priceType,
            'Payment': {
                'Amount': this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total ? this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total : 0,
                'BillingAddress': {
                    'Address1': this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 : '',
                    'Address2': this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 : '',
                    'Address3': ' ',
                    'City': this._enrollSession.data.RetailOrderSummary.billingAddressData.City ? this._enrollSession.data.RetailOrderSummary.billingAddressData.City : '',
                    'CountryCode': this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode : '',
                    'State': this._enrollSession.data.RetailOrderSummary.billingAddressData.State ? this._enrollSession.data.RetailOrderSummary.billingAddressData.State : '',
                    'Zip': this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode : '',
                    'FirstName': this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName : '',
                    'LastName': this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName : '',
                    'BusinessName': ''
                },
                'CardNumber': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber : ''),
                // 'CardNumber': this._auth.isLoggedIn()?(this._enrollSession.data.RetailOrderSummary.creditCardNumberDisplayField?this._enrollSession.data.RetailOrderSummary.creditCardNumberDisplayField:''):(this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber?this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber : ''),
                'CardType': this._enrollSession.data.RetailEnroll.CardType ? this._enrollSession.data.RetailEnroll.CardType : '',
                'CurrencyCode': this._enrollSession.data.RetailEnroll.CurrencyCode ? this._enrollSession.data.RetailEnroll.CurrencyCode : '',
                'ExpirationMonth': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth : ''),
                'ExpirationYear': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear : ''),
                // 'ExpirationMonth': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.ExpirationMonth?this._enrollSession.data.RetailOrderSummary.ExpirationMonth: '') :(this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth ?this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth: ''),
                // 'ExpirationYear': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.expirationYearField ? this._enrollSession.data.RetailOrderSummary.expirationYearField: '') :( this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear : ''),
                'NameOnCard': this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard : '',
                'SavePayment': true,
                'CvcCode': this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode : '',
                'SelectedCc': 1,
                // 'LastFour': this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize.lastFour,
                // 'FirstSix': this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize.firstSix
            },
    
            'Payment2': {
                'Amount': 0,
                'BillingAddress': {
                    'Address1': '',
                    'Address2': '',
                    'Address3': '',
                    'City': '',
                    'CountryCode': '',
                    'State': '',
                    'Zip': ''
                },
                'CardNumber': '',
                'CardType': '',
                'CurrencyCode': '',
                'ExpirationMonth': 0,
                'ExpirationYear': 0,
                'NameOnCard': '',
                'SavedPaymentMethodID': '',
                'SavePayment': '',
                'CvcCode': '',
                'SelectedCc': 1
            },
            'Phone1': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
            'Phone2': '',
            'MobilePhone': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
            'FaxNumber': '',
            'sendSMS': this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
            'sendEmails': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
            'ShippingAddress': {
                'Address1': this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
                'City': this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
                'CountryCode': this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
                'State': this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
                'Zip': this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode,
                'FirstName': this._enrollSession.data.RetailOrderSummary.shippingFormData.firstName,
                'LastName': this._enrollSession.data.RetailOrderSummary.shippingFormData.lastName,
                'BusinessName': '',
                'Address2': this._enrollSession.data.RetailOrderSummary.shippingFormData.address2,
                'Address3': ''
            },
    
            'SiteId': '',
            'SponsorId': this._enrollSession.data.RetailEnroll.SponsorId ? this._enrollSession.data.RetailEnroll.SponsorId : '',
            'EnrollerId': this._enrollSession.data.RetailEnroll.EnrollerId ? this._enrollSession.data.RetailEnroll.EnrollerId : '',
            'StoreID': '',
            'TextNumber': '',
            'Username': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
            'WebPageItemID': '',
            'WebPageURL': '',
            'DirectDeposit': {
                'NameOnAccount': this._enrollSession.data.RetailEnroll.CommissionNameOnAccount ? this._enrollSession.data.RetailEnroll.CommissionNameOnAccount : '',
                'BankAccountNumber': this._enrollSession.data.RetailEnroll.CommissionAccNo ? this._enrollSession.data.RetailEnroll.CommissionAccNo : '',
                'BankRoutingNumber': this._enrollSession.data.RetailEnroll.CommissionRoutingNo ? this._enrollSession.data.RetailEnroll.CommissionRoutingNo : '',
                'DepositAccountType': '',
                'BankName': this._enrollSession.data.RetailEnroll.CommissionBankName ? this._enrollSession.data.RetailEnroll.CommissionBankName : '',
                'BankAddress': '',
                'BankCity': '',
                'BankState': '',
                'BankZip': '',
                'BankCountry': '',
                'Iban': '',
                'SwiftCode': ''
            },
            'BillingAddressPreference': this._enrollSession.data.RetailEnroll.BillAddress === 'yes' ? 0 : 1,
            'CommissionPayableType': '',
            'CommissionPayableTo': this._enrollSession.data.RetailEnroll.payAbleTo ? this._enrollSession.data.RetailEnroll.payAbleTo : '',
            'HasRequestedOptIn': (this._enrollSession.data.RetailEnroll.ReceiveText || this._enrollSession.data.RetailEnroll.ReceiveEmail) ? true : false,
            'IsNotBackOffice': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? true : false,
            'GiftCardCode': '',
            'UsePrimaryCard': this._auth.isLoggedIn()? (this._enrollSession.data.RetailOrderSummary.paymentTypeData?.primaryPaymentCard == 'true'? true: false) : (this._enrollSession.data.usePrimaryCard ? this._enrollSession.data.usePrimaryCard : ''),
            'SMSOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
            'EmailOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
            "AutoOrderProcessType": this.SubscriptionProcessType,
            "IsNewAddress":  this.LoggedInUser.CustomerID ? true : false,
            "IsLoggedInCustomer": this._auth.isLoggedIn()? true : false,
            "IsTokenEx": true
        };
        if (this._enrollSession.data.SmartOrderProductDetalis && this._enrollSession.data.SmartOrderProductDetalis.length > 0 && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
            request.AutoOrderRequest =
            {
                'AutoOrderCartID': '',
                'AutoShipStartDate': this.SmartOrderDate,
                'AutoshipType': 0,
                'BeginMonth': 0,
                'Details': this._enrollSession.data.SmartOrderProductDetalis,
                'ShipMethodID': this._enrollSession.data.RetailEnroll.ShippingMethod || this._enrollSession.data.Order_shipMethodIDField,
                "AutoshipId": this._auth.isLoggedIn() ? sessionStorage.getItem('AutoOrderID')   : "",
            };
        }
        if ((this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total === 0 &&
            this._enrollSession.data.RetailEnroll.IsNonprofit && this._enrollSession.data.FileUploaded)) {
            request.Payment.BillingAddress = {};
        }
        this._restService.SubmitApplication(request).subscribe((result: any) => {
            this.submissionInProgress = false;
            if (result) {
                this._enrollSession.submitApplicationData = result;
                sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
                this._enrollSession.data.RetailEnroll.orderDetails = result;
                if (result.OrderID > 0) {
                    if (this._enrollSession.data.FileUploaded) {
                        this.FunUploadDocument();
                    }
                    this.router.navigate(['/Confirmation']);
                } else {
                    this._toastr.error(result.Result.errorsField, 'Error');
    
                }
            } else {
                this._toastr.error('Something went wrong please try again', 'Error');
    
            }
        }, (err) => {
            this.submissionInProgress = false;
            this._toastr.error('Something went wrong please try again', 'Error');
    
        }, () => {
    
        });
      }

    FocusOutTokenEx(tokenCheck:boolean) {
        this.tokenizer.tokenize({
            success: (data) => {
              console.log('tokenized success', data);
              var token = data.token;
              $(".creditcard-token").val(token);
              $(".creditcard-display").val(token.substr(token.length - 4, 4));
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = token;
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize = data;
              this._enrollSession.data.RetailEnroll.CardType = data.cardType;
              this.validateCardNumber(this._enrollSession.data.RetailOrderSummary.paymentTypeData?.tokenize?.firstSix);
              this.tokenError = false;
            },
            error: (data) => {
              this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber= "";
              this._enrollSession.data.RetailEnroll.CardType = "";
              this.tokenError = true;
            }
          });
          this.tokenizer.iframe.on('error',function(data) {
            console.log("dataErrorToken",data);
            let count = 0;
            if(data.error && count < 1){
                count++;
                alert(data.error + "\n Are you still want  to continue?");
                window.location.reload();
            }
        });
    }

    getCustomerSite(webAlias, type): void {
        try {
            if (webAlias) {
                const request = '?WebAlias=' + webAlias;
                this._restService.GetCustomerSite(request).subscribe((result: any) => {
                    if (type === 1) {
                        if (result.Data) {
                            this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                            this._enrollSession.data.RetailEnroll.SponsorId = result.Data.customerIDField;
                            this._enrollSession.data.RetailEnroll.EnrollerId = result.Data.customerIDField;
                            this._enrollSession.data.RetailEnroll.webAliasField = result.Data.webAliasField;
                            this._enrollSession.data.CustomerWebAlias = result.Data.webAliasField;
                            this._enrollSession.data.ValidGiftCardCode = this._enrollSession.data.RetailEnroll.webAliasField;
                            this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                            this._enrollSession.data.ReferredBy = result.Data.webAliasField;
                            this._enrollSession.data.RetailEnroll.isValidwebalias = true;
                            this.createIframe();
                        } else {
                            this._enrollSession.data.RetailEnroll.isValidwebalias = 'false';
                            this._toastr.error('Invalid WebAlias.', 'Error');
                            this._enrollSession.data.CustomerWebAlias = '';
                            this._enrollSession.data.ReferredBy = '';
                            this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                           
                        }
                    }
                    else {
                        if (result.Data) {
                            this._enrollSession.data.ReferredByGiftCard = '';
                            this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                            this._toastr.error('This Referral Code is not Available', 'Error');
                        }
                        else {
                            this._toastr.success('Referral Code Available', 'Success');
                            this.createIframe();
                        }
                    }
                }, (err) => {
                    console.log(err);
                    this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                    this._toastr.error('Somthing is wrong Please try again.', 'Error');
                }, () => {

                });
            }
        } catch (e) {
            this._toastr.error('Somthing is wrong Please try again.', 'Error');
        }
    }
    regexValidateZip1(event): void {
        const billingAddressForm = this.billingAddressForm ? this.billingAddressForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(billingAddressForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
    FunUploadDocument(): void {

        const file = new FormData();


        const byteString = atob(this._enrollSession.data.FileData.split(',')[1]);

        const ab = new ArrayBuffer(byteString.length);

        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);

        }
        const type = this._enrollSession.data.FileName.substring(this._enrollSession.data.FileName.lastIndexOf('.') + 1);

        const bb = new Blob([ab], { type: type });

        file.append('FileName', this._enrollSession.data.FileName);
        file.append('CustomerId', this._enrollSession.data.RetailEnroll.orderDetails.CustomerID);
        file.append('Image', bb);

        this._enrollSession.data.FileData = file;

        const request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                if (request.status === 200) {

                }
            }
        };
        let requestWebServiceUrl = '';
        requestWebServiceUrl = API_URL + 'api/NonProfitEnrollment/UploadFile';
        request.open('POST', requestWebServiceUrl, true);
        request.setRequestHeader('Accept', 'application/json');


        request.send(file);
    }
    validateCardNumber(cardNumber?: any) {
        let length = cardNumber?.toString().length;
        let firstdigit = getDigit(cardNumber,length);
        if((firstdigit === 3 || firstdigit === 4 || firstdigit === 5 || firstdigit === 6)){
            this.hasCardNumberCheck = true;
        }
        else{
            this.hasCardNumberCheck = false;
        }
        this.validateSecurityCode(firstdigit);
    }
    validateSecurityCode(firstdigit?:any){
        if(firstdigit === 3){
            this.hasSecurityCodeForAmerican = true;
            this.hasSecurityCodeForVDM = false;
        }
        else if(firstdigit === 4 || firstdigit === 5 || firstdigit === 6){
            this.hasSecurityCodeForVDM = true;
            this.hasSecurityCodeForAmerican = false;
        }
        else{
            this.hasSecurityCodeForVDM = false;
            this.hasSecurityCodeForAmerican = false;
        }
    }
}
function getDigit(number, n) {
    return Math.floor((number / Math.pow(10, n - 1)) % 10);
  }