<div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
     <summary-cart *ngIf="_enrollSession.mobileView" (submitApplication)="fraudPrevention()"  [billingAddressForm]="billingAddressForm" [paymentTypeForm]="paymentTypeForm" [referredForm]="referredForm"></summary-cart>
      <div class="checkout-div">
      <app-stepper  *ngIf="!_enrollSession.mobileView"></app-stepper>
       
        <div class="payment-info-container">
          <h1 class="checkout-h1">Checkout</h1>
          <div class="payment-customer-info-main-div">
            <div class="payment-customer-info-div">
              <div class="payment-customer-info-text-1">Contact</div>
              <div class="payment-customer-info-text-2">{{_enrollSession.data.RetailOrderSummary.customerInfoData.Email}}</div>
              <div class="payment-customer-info-text-3 cursor-pointer" routerLink="../Shipping">Change</div>
            </div>
            <div class="payment-customer-info-div-middle">
              <div class="payment-customer-info-text-1">Ship to</div>
              <div class="payment-customer-info-text-2"><span>
                {{_enrollSession.data.RetailOrderSummary.shippingFormData.address1}} {{_enrollSession.data.RetailOrderSummary.shippingFormData.address2}},
                {{_enrollSession.data.RetailOrderSummary.shippingFormData.City}} {{_enrollSession.data.RetailOrderSummary.shippingFormData.State}},
                {{_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode}}, {{_enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode}}
              </span>
              </div>
              <div class="payment-customer-info-text-3 cursor-pointer" routerLink="../Shipping">Change</div>
            </div>
            <div class="payment-customer-info-div-bottom">
              <div class="payment-customer-info-text-1">Method</div>
              <div class="payment-customer-info-text-2">{{_enrollSession.data.RetailEnroll.ShippingDescription || _enrollSession.data.Shipping_descriptionField}}</div>
              <div class="payment-customer-info-text-3 cursor-pointer" routerLink="../Shipping">Change</div>
            </div>
          </div>
          <!-- <h1 class="checkout-h1 payment-h1" *ngIf="!_auth.isLoggedIn()">Referred By</h1> -->
          <div class="mtb-30 d-flexCustom" *ngIf="!_auth.isLoggedIn()">
            <span class="checkout-h1 payment-h1 mr-50" >Referral Code</span>
            <span class="mr-50 ReferredBy" *ngIf="_enrollSession.data.RetailEnroll.webAlias">Referred By: {{_enrollSession.data.RetailEnroll.webAlias}}</span>
            <span class="referralText" *ngIf="_enrollSession.data.RetailEnroll.webAlias">Did someone else refer you? Please enter the correct Referral code below!</span>
          </div>
          <div class="referred-by-form-block w-form" *ngIf="!_auth.isLoggedIn()">
            <form [formGroup]="referredForm" id="email-form-3" name="email-form-3" class="referred-by-form">
              <input type="text" class="referred-by-text-form-field w-input" maxlength="256" name="referralCode" formControlName="referralCode" [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
               placeholder="Enter your referral code*" id="Referral-Code" (focusout)="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode?getCustomerSite(_enrollSession.data.RetailOrderSummary.referredFormData.referralCode,1):''">
              <span class="help-block" *ngIf="!referredForm.get('referralCode').valid&&referredForm.get('referralCode').touched">
                <span *ngIf = "referredForm.get('referralCode').errors['required']">This field is required</span>
              </span>
            </form>
          </div>
          <h1 class="checkout-h1 payment-h1">Payment</h1>
          <div class="payment-p">All transactions are secure and encrypted.</div>
          <div class="payment-form-block w-form">
            <form [formGroup]="paymentTypeForm" id="email-form-2" class="payment-form" *ngIf="!_auth.isLoggedIn()">
              <div fxLayout="column">
                <label class="radio-button-field w-radio p-0">
                  <span class="radio-button-label w-form-label">Credit/Debit Card</span>
                </label>

                <label class="f-s-14 p-l-10 w-form-label">Card Number*</label>
                <div id="cardiframe" class="cardiframe payment-text-field w-input">
                  <input type="hidden" maxlength="24" class="payment-text-field w-input p-iframe" maxlength="17" formControlName="cardNumber"/>
                  <div id="cc-wrapper" class="cc-wrapper"></div>
                  <input type="hidden" class="creditcard-token" id="Token" formControlName="cardNumber"/>
                  <input type="hidden" class="creditcard-display" id="Display" formControlName="cardNumber"/>
                  <span class="help-block" *ngIf="!paymentTypeForm.get('cardNumber').valid&&paymentTypeForm.get('cardNumber').touched">
                    <span *ngIf = "paymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
                  </span>
                </div>
                <span class="Error-font-size">
                  <span class="help-blocks" *ngIf="(hasCardNumberCheck !== undefined && hasCardNumberCheck === false || tokenError === true)">
                    Invalid Card
                    Number
                  </span>
                </span>

                <!-- <input type="number" class="payment-text-field w-input" maxlength="16" name="cardNumber" formControlName="cardNumber" placeholder="Card number*" id="Card-Number" required>
                <span class="help-block" *ngIf="!paymentTypeForm.get('cardNumber').valid&&paymentTypeForm.get('cardNumber').touched">
                  <span *ngIf = "paymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
                </span> -->
                
                <input type="text" class="payment-text-field w-input" maxlength="256" name="nameOnCard" formControlName="nameOnCard"  placeholder="Name on card*" id="Name-on-card" required>
                <span class="help-block" *ngIf="!paymentTypeForm.get('nameOnCard').valid&&paymentTypeForm.get('nameOnCard').touched">
                  <span *ngIf = "paymentTypeForm.get('nameOnCard').errors['required']">This field is required</span>
                  <span class="Error-font-size" *ngIf="!paymentTypeForm.get('nameOnCard').errors['required'] && !paymentTypeForm.get('nameOnCard').valid">Invalid
                    Card Holder Name</span>
                </span>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                    <div fxFlex="49" fxLayout="column">
                      <div>
                        <select name="expMonth" formControlName="expMonth" class="payment-text-field w-input" placeholder="Select Month*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth">
                          <option value="" disabled selected hidden>Select Month*</option>
                          <option *ngFor="let month of monthList" [value]='month.key'>
                            {{month.value}}</option>
                        </select>
                      </div>
                      <div>
                        <span class="help-block" *ngIf="!paymentTypeForm.get('expMonth').valid&&paymentTypeForm.get('expMonth').touched">
                          <span *ngIf = "paymentTypeForm.get('expMonth').errors['required']">This field is required</span>
                        </span>
                      </div>
                    </div>
                    <div fxFlex="49" fxLayout="column">
                      <div>
                        <select name="expYear" formControlName="expYear" class="payment-text-field w-input" placeholder="Select Year*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear">
                          <option value="" selected disabled hidden>Select Year*</option>
                          <option *ngFor="let year of yearList" [value]='year.key'>
                            {{ year.value}}</option>
                        </select>
                      </div>
                      <div>
                        <span class="help-block" *ngIf="!paymentTypeForm.get('expYear').valid&&paymentTypeForm.get('expYear').touched">
                          <span *ngIf = "paymentTypeForm.get('expYear').errors['required']">This field is required</span>
                        </span>
                      </div>
                    </div>
                </div>
                <input type="text" class="payment-text-field w-input" [minlength]="(hasSecurityCodeForAmerican && !hasSecurityCodeForVDM ? 4 : 3)" [maxlength]="(hasSecurityCodeForAmerican && !hasSecurityCodeForVDM ? 4 : 3)" name="securityCode" formControlName="securityCode"  placeholder="Security code*" id="Security-code" required>
                <span class="help-block" *ngIf="!paymentTypeForm.get('securityCode').valid&&paymentTypeForm.get('securityCode').touched">
                    <span *ngIf="paymentTypeForm.get('securityCode').errors['required']">This field is required</span>
                    <span *ngIf="!paymentTypeForm.get('securityCode').errors['required'] && !paymentTypeForm.get('securityCode').valid">Invalid Security code</span>
                </span>
              </div>
            </form>
            <div class="payment-form-block w-form" *ngIf="_auth.isLoggedIn()">
              <form [formGroup]="paymentTypeForm" id="email-form-2" class="payment-form">
                <label class="billing-address-radio-button-field-top w-radio" *ngIf="creditCardNumberDisplayField">
                  <input type="radio" id="radio" name="primaryPaymentCard" value="true" formControlName="primaryPaymentCard" class="w-form-formradioinput shipping-method-radio-button w-radio-input" (click)="differentPaymentMethod('paymentSame')">
                  <span class="billing-address-radio-button-label w-form-label" [textContent]="'Card ending in' + creditCardNumberDisplayField"></span><br>
                  <span class="billing-address-radio-button-label w-form-label s_expiration" [textContent]="'Expiration ' + expirationMonthField +'/'+ expirationYearField"></span>
                </label>
                <label class="billing-address-radio-button-field-top w-radio" *ngIf="!creditCardNumberDisplayField">
                  <span class="billing-address-radio-button-label w-form-label f-w-800" [textContent]="'No Card Saved'"></span><br>
                </label>
                <label class="billing-address-radio-button-field w-radio" [ngClass]="{'b-top':creditCardNumberDisplayField}">
                  <input type="radio" id="radio-3" name="primaryPaymentCard" value="false" formControlName="primaryPaymentCard" class="w-form-formradioinput shipping-method-radio-button w-radio-input" (click)="differentPaymentMethod('paymentDifferent')">
                  <span class="billing-address-radio-button-label w-form-label">Add New Payment</span>
                </label>
                <ng-container *ngIf="paymentTypeForm.get('primaryPaymentCard').value == 'false'">
                  <div fxLayout="column">
                    <label class="radio-button-field w-radio p-0">
                      <span class="radio-button-label w-form-label">Credit/Debit Card</span>
                    </label>

                    <label class="f-s-14 p-l-10 w-form-label">Card Number*</label>
                    <div id="cardiframe" class="cardiframe payment-text-field w-input">
                      <input type="hidden" maxlength="24" class="payment-text-field w-input p-iframe" maxlength="17" formControlName="cardNumber"/>
                      <div id="cc-wrapper" class="cc-wrapper"></div>
                      <input type="hidden" class="creditcard-token" id="Token" formControlName="cardNumber"/>
                      <input type="hidden" class="creditcard-display" id="Display" formControlName="cardNumber"/>
                      <span class="help-block" *ngIf="!paymentTypeForm.get('cardNumber').valid&&paymentTypeForm.get('cardNumber').touched">
                        <span *ngIf = "paymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
                      </span>
                    </div>
                    <span class="Error-font-size">
                      <span class="help-blocks" *ngIf="(hasCardNumberCheck !== undefined && hasCardNumberCheck === false || tokenError === true)">
                        Invalid Card
                        Number
                      </span>
                    </span>
                    <!-- <input type="number" class="payment-text-field w-input" maxlength="16" name="cardNumber" formControlName="cardNumber" placeholder="Card number*" id="Card-Number" required>
                    <span class="help-block" *ngIf="!paymentTypeForm.get('cardNumber').valid&&paymentTypeForm.get('cardNumber').touched">
                      <span *ngIf = "paymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
                    </span> -->
                    <input type="text" class="payment-text-field w-input" maxlength="256" name="nameOnCard" formControlName="nameOnCard"  placeholder="Name on card*" id="Name-on-card" required>
                    <span class="help-block" *ngIf="!paymentTypeForm.get('nameOnCard').valid&&paymentTypeForm.get('nameOnCard').touched">
                      <span *ngIf = "paymentTypeForm.get('nameOnCard').errors['required']">This field is required</span>
                      <span class="Error-font-size" *ngIf="!paymentTypeForm.get('nameOnCard').errors['required'] && !paymentTypeForm.get('nameOnCard').valid">Invalid
                        Card Holder Name</span>
                    </span>
                    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                        <div fxFlex="49" fxLayout="column">
                          <div>
                            <select name="expMonth" formControlName="expMonth" class="payment-text-field w-input" placeholder="Select Month*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth">
                              <option value="" disabled selected hidden>Select Month*</option>
                              <option *ngFor="let month of monthList" [value]='month.key'>
                                {{month.value}}</option>
                            </select>
                          </div>
                          <div>
                            <span class="help-block" *ngIf="!paymentTypeForm.get('expMonth').valid&&paymentTypeForm.get('expMonth').touched">
                              <span *ngIf = "paymentTypeForm.get('expMonth').errors['required']">This field is required</span>
                            </span>
                          </div>
                        </div>
                        <div fxFlex="49" fxLayout="column">
                          <div>
                            <select name="expYear" formControlName="expYear" class="payment-text-field w-input" placeholder="Select Year*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear" required>
                              <option value="" selected disabled hidden>Select Year*</option>
                              <option *ngFor="let year of yearList" [value]='year.key'>{{ year.value}}</option>
                            </select>
                          </div>
                          <div>
                            <span class="help-block" *ngIf="!paymentTypeForm.get('expYear').valid&&paymentTypeForm.get('expYear').touched">
                              <span *ngIf = "paymentTypeForm.get('expYear').errors['required']">This field is required</span>
                            </span>
                          </div>
                        </div>
                    </div>
                    <input type="text" class="payment-text-field w-input" [minlength]="(hasSecurityCodeForAmerican && !hasSecurityCodeForVDM ? 4 : 3)" [maxlength]="(hasSecurityCodeForAmerican && !hasSecurityCodeForVDM ? 4 : 3)" name="securityCode" formControlName="securityCode"  placeholder="Security code*" id="Security-code" required>
                    <span class="help-block" *ngIf="!paymentTypeForm.get('securityCode').valid&&paymentTypeForm.get('securityCode').touched">
                        <span *ngIf="paymentTypeForm.get('securityCode').errors['required']">This field is required</span>
                        <span *ngIf="!paymentTypeForm.get('securityCode').errors['required'] && !paymentTypeForm.get('securityCode').valid">Invalid Security code</span>
                    </span>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
          <h1 class="checkout-h1 payment-h1">Billing Address</h1>
          <div class="payment-p">Select the address that matches your card or payment method.</div>
          <div class="billing-address-form w-form">
            <form [formGroup]="billingAddressForm" id="email-form" name="email-form" class="billing-address-form-div">
              <label class="billing-address-radio-button-field-top w-radio">
                <input type="radio" id="radio" name="sameAddress" value="true" formControlName="sameAddress" class="w-form-formradioinput shipping-method-radio-button w-radio-input">
                <span class="billing-address-radio-button-label w-form-label">Same as shipping address</span>
              </label>
              <label class="billing-address-radio-button-field w-radio">
                <input type="radio" id="radio-3" name="sameAddress" value="false" formControlName="sameAddress" class="w-form-formradioinput shipping-method-radio-button w-radio-input">
                <span class="billing-address-radio-button-label w-form-label">Use a different billing address</span>
              </label>
              <ng-container *ngIf="billingAddressForm.get('sameAddress').value == 'false'">
                <div fxLayout="column" class="p-20-15-10">
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                    <div fxLayout="column" fxFlex="49">
                      <div>
                        <input type="text" class="form-field w-input" maxlength="30" name="First-Name" formControlName="firstName" placeholder="First Name*" id="First-Name" required>
                      </div>
                      <div class="error-box">
                        <span class="help-block" *ngIf="!billingAddressForm.get('firstName').valid&&billingAddressForm.get('firstName').touched">
                          <span *ngIf = "billingAddressForm.get('firstName').errors['required']">This field is required</span>
                        </span>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="49">
                      <div>
                        <input type="text" class="form-field  w-input" maxlength="30" name="Last-Name" formControlName="lastName" placeholder="Last Name*" id="Last-Name-2" required>
                      </div>
                      <div class="error-box">
                        <span class="help-block p-l-10" *ngIf="!billingAddressForm.get('lastName').valid&&billingAddressForm.get('lastName').touched">
                          <span *ngIf = "billingAddressForm.get('lastName').errors['required']">This field is required</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxFlex="100">
                    <div fxLayout="column" fxFlex="100">
                      <div>
                        <input type="text" class="form-field w-input" maxlength="50" name="Address-1" formControlName="address1" placeholder="Address 1*" id="Address-1" required>                    </div>
                      <div class="error-box">
                        <span class="help-block" *ngIf="!billingAddressForm.get('address1').valid&&billingAddressForm.get('address1').touched">
                          <span *ngIf = "billingAddressForm.get('address1').errors['required']">This field is required</span>
                        </span>
                      </div>
                   </div>
                   </div>
                   <div fxLayout="row" fxFlex="100">
                    <input type="text" class="form-field w-input" maxlength="50" name="address-2" formControlName="address2" placeholder="Address 2 (Optional)" id="address-3">
                   </div>
                   <div fxLayout="row" fxFlex="100">
                    <div fxLayout="column" fxFlex="100">
                      <div>
                        <input type="text" class="form-field w-input" maxlength="30" name="City" formControlName="City" placeholder="City*" id="City-2" required>
                      </div>
                      <div class="error-box">
                        <span class="help-block p-l-10" *ngIf="!billingAddressForm.get('City').valid&&billingAddressForm.get('City').touched">
                          <span *ngIf = "billingAddressForm.get('City').errors['required']">This field is required</span>
                        </span>
                      </div>
                   </div>
                 </div>
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                    <div fxLayout="column" fxFlex="32">
                      <div>
                        <select name="CountryCode" formControlName="CountryCode" class="form-field w-input" [(ngModel)]="_enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode"
                        (change)="getStates(this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode)">
                          <option *ngFor="let country of _enrollSession.data.countriesList" [value]='country.countryCodeField'>{{ country.countryNameField}} </option>
                        </select>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="32">
                      <div>
                        <div>
                          <select name="State" formControlName="State" class="form-field w-input" [(ngModel)]="!_enrollSession.data.RetailOrderSummary.billingAddressData.State ? '' : _enrollSession.data.RetailOrderSummary.billingAddressData.State" required>
                            <option value="" selected disabled hidden>{{_enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode ==="US" ? "select state": "select province"}}</option>
                            <option *ngFor="let state of _enrollSession.data.stateList"
                            [ngValue]='state.regionCodeField'>{{ state.regionNameField}}</option>
                          </select>
                        </div>
                        <div class="error-box">
                          <span class="help-block p-l-10" *ngIf="!billingAddressForm.get('State').valid&&billingAddressForm.get('State').touched">
                            <span *ngIf = "billingAddressForm.get('State').errors['required']">This field is required</span>
                          </span>
                        </div>
                     </div>
                    </div>
                    <div fxLayout="column" fxFlex="32">
                      <div>
                       <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code" formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2"  (keyup)="regexValidateZip1($event)" required>
                      </div>
                      <div class="error-box">
                       <span class="error-zip" *ngIf="invalidzip1">INVALID ZIP</span>
                        <span class="help-block" *ngIf="!billingAddressForm.get('ZipCode').valid&&billingAddressForm.get('ZipCode').touched">
                          <span *ngIf = "billingAddressForm.get('ZipCode').errors['required']">This field is required</span>
                        </span>
                      </div>
                   </div>
                  </div>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
      <summary-cart *ngIf="!_enrollSession.mobileView" (submitApplication)="fraudPrevention()"  [billingAddressForm]="billingAddressForm" [paymentTypeForm]="paymentTypeForm" [referredForm]="referredForm"></summary-cart>
    </div>
  </div>