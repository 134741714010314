<div class="dialog-content-wrapper password-reset" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div" id="pop-up-div">
        <div class="pop-up-login-div" id="login_container">
          <h3 class="text-center p-t-20 f-s-24">Reset Password</h3>
          <br>
          <form [formGroup]="customerLoginForm" name="Login" id="login">
              <div fxLayout="column" class="p-20-15-10">
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="password" class="form-field w-input input-field"
                                  name="password" formControlName="password" placeholder="Enter Password"
                                  id="Password" [(ngModel)]="userInfo.password" required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('password').valid && customerLoginForm.get('password').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('password').errors['required']">This
                                      field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="password" class="form-field w-input"
                                  [(ngModel)]="userInfo.reenterPassword" name="reenterPassword"
                                  formControlName="reenterPassword" placeholder="Re-Enter Password" id="reenterPassword"
                                  required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('reenterPassword').valid && customerLoginForm.get('reenterPassword').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('reenterPassword').errors['required']"
                                      >This field is required</span>
                                      <span
                                      *ngIf="customerLoginForm.get('reenterPassword').errors['passwordMismatch']"
                                      >Password Does Not Matched</span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
              <br>
              <div layout="row" fxLayout-sm="row" class="m-t-20 login-bottom"
                  fxLayout-xs="column" fxLayout-align="start space-around"
                  fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                  <button type="submit" class="btn-project w-80 upper-case f-s-18 m-l-custom1" color="primary" [ngClass]="{'noClick':appLoader}"
                      mat-raised-button (click)="passwordReset()">Reset</button>
                  <button class="btn-project w-80 upper-case f-s-18 m-l-custom" color="primary"
                      mat-raised-button (click)="backtohome()">Cancel</button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>

