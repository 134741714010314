import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as _ from 'underscore';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReviewPageComponent } from './ReviewPage.component';

const routes: Routes = [
  {
    path: 'Review-Page',
    component: ReviewPageComponent
  }
];


@NgModule({
  declarations: [
    ReviewPageComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgpSortModule,
    MatStepperModule,
    NgxChartsModule,
    CommonModule,
    TranslateModule,
    SummaryCartModule,
    GoogleMapsModule,
    FlexLayoutModule
  ]
})
export class ReviewPageModule { }
