<div class="dialog-content-wrapper" id="two-way-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
  </mat-toolbar>
  <div style="display:flex" class="pop-up-background">
    <div class="pop-up-div">
      <h1 class="pop-up-headline">Are you sure you Want to pass <br>on these Subscription benefits?</h1>
      <div mat-dialog-content>
        <ul role="list" class="pop-up-list">
          <li class="pop-up-list-item">Consistency towards goals</li>
          <li class="pop-up-list-item">ability to get free products</li>
          <li class="pop-up-list-item">maximum savings</li>
          <li class="pop-up-list-item">50% more rewards points.</li>
        </ul>
      </div>
      <div class="pop-up-button-div">
        <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="onSubscribe('false')">No
          Subscription Today</button> &nbsp;
        <button mat-button aria-current="page" class="pop-up-button w-button w--current" [mat-dialog-close]=""
          cdkFocusInitial (click)="onSubscribe('true')">Subscribe &amp; Save</button>
      </div>
    </div>
  </div>
</div>